<template>
    <div class="header-content pb-3 pb-md-0">
        <div class="container">
            <div class="row align-items-center">
                <div class="col col-md-auto">
                    <div class="d-flex align-items-center">
                        <div class="mobile-toggle-menu d-lg-none px-lg-2" data-trigger="#navbar_main"><i class='bx bx-menu'></i>
                        </div>
                        <div class="logo d-none d-lg-flex">
                            <router-link to="/">
                                <img src="/assets_store_emmeline/assets/images/logo.png" class="logo-icon" alt="" />
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md order-4 order-md-2">
                    <div class="input-group flex-nowrap px-xl-4">
                        <input type="text" class="form-control w-100" v-model="search_data" v-on:keyup.enter="search()" placeholder="Search for Products">
                        <span class="input-group-text cursor-pointer" @click="search()"><i class='bx bx-search'></i></span>
                    </div>
                </div>
                <div class="col col-md-auto order-3 d-none d-xl-flex align-items-center">
                    <div class="fs-1 text-white"><i class='bx bx-headphone'></i>
                    </div>
                    <div class="ms-2">
                        <p class="mb-0 font-13">CALL US NOW</p>
                        <h5 v-if="this.branchInfo" class="mb-0">{{ getPreference("Phone") }}</h5>
                    </div>
                </div>
                <div class="col col-md-auto order-2 order-md-4">
                    <div class="top-cart-icons">
                        <nav class="navbar navbar-expand">
                            <ul class="navbar-nav ms-auto">
                                <li v-if="loginame" class="nav-item">
                                    <router-link style="font-size:20px; line-height: 48px;" to="/order" class="nav-link cart-link">{{ loginame }}</router-link>
                                </li>

                                <li v-else class="nav-item">
                                    <router-link  to="/signin" class="nav-link cart-link"><i class='bx bx-user'></i></router-link>
                                </li>

                                <li v-if="cart" class="nav-item dropdown dropdown-large">
                                    <a @click="showCart" href="javascript:void(0)" class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative cart-link">
                                        <span v-if="cart" class="alert-count-cus">{{ cart.totalqty }}</span>
                                        <i class='bx bx-shopping-bag'></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-end" :class="classShowCart?'show':''">
                                        <div v-if="!checkoutForm">
                                            <a href="javascript:;">
                                                <div class="cart-header">
                                                    <p v-if="cart" class="cart-header-title mb-0">{{cart.totalqty}} {{ cart.totalqty == 1?'ITEM':'ITEMS' }} </p>
                                                    <p @click="closeCart()" class="cart-header-clear ms-auto mb-0"><button type="button" class="btn-close float-end"></button></p>
                                                </div>
                                            </a>
                                            <div v-if="cart" class="cart-list">

                                                <a v-for="(item, idx) in cart.item" :key="idx" class="dropdown-item" href="javascript:;">
                                                    <div class="d-flex align-items-center">
                                                        <div class="flex-grow-1">
                                                            <h6 class="cart-product-title">{{item.name}}</h6>
                                                            <p class="cart-product-price"><input @change="changeQty(item,$event)" min="1" style="font-size: 18px; width: 78px; height: 24px;" type="number" :value="item.qty" /> <span style="font-size: 16px;">X ${{ item.price }}</span></p>
                                                        </div>
                                                        <div class="position-relative">
                                                            <div @click="removeCart(item.id)" class="cart-product-cancel position-absolute"><i class='bx bx-x'></i>
                                                            </div>
                                                            <div class="cart-product">
                                                                <img :src="item.picture" class="" alt="product image">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>

                                            </div>
                                            <a v-if="cart" href="javascript:;">
                                                <div class="text-center cart-footer d-flex align-items-center">
                                                    <h5 class="mb-0">TOTAL</h5>
                                                    <h5 class="mb-0 ms-auto">${{ cart.totalprice.toFixed(2) }}</h5>
                                                </div>
                                            </a>
                                            <div v-if="cart.totalqty" class="d-grid p-3 border-top">
                                                <div v-if="loginame">
                                                    <div v-if="btnupdateOrder" class="d-flex justify-content-center">
                                                        <a @click="canceleditoder()" href="javascript:;" class="btn btn-light btn-ecomm mr-2">Cancel Edit</a>
                                                        <a @click="tbn_updateOrder()" href="javascript:;" class="btn btn-light btn-ecomm">Update Order</a>
                                                    </div>
                                                    <a v-else style="width: 100%" @click="checkoutForm = true" href="javascript:;" class="btn btn-light btn-ecomm">CHECKOUT</a>
                                                </div>
                                                <div v-else>
                                                    <router-link style="width: 100%" to="/signin" class="btn btn-light btn-ecomm">Sign in</router-link>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="checkoutForm">
                                            <a href="javascript:;">
                                                <div class="cart-header">
                                                    <p v-if="cart" class="cart-header-title mb-0">{{cart.totalqty}} {{ cart.totalqty == 1?'ITEM':'ITEMS' }} </p>
                                                    <p @click="closeCart()" class="cart-header-clear ms-auto mb-0"><button type="button" class="btn-close float-end"></button></p>
                                                </div>
                                            </a>
                                            <div class="cart-list p-2">
                                                <form class="row g-3">
                                                    <div class="col-12">
                                                        <label for="address" class="form-label">Enter Address</label>
                                                        <input type="text" class="form-control" placeholder="Enter Address" v-model="address_order"/>
                                                    </div>
                                                    <div class="col-12">
                                                        <label for="phone_number" class="form-label">Enter Phone Number</label>
                                                        <input type="number" class="form-control" placeholder="Enter Phone Number" v-model="phone_order" />
                                                    </div>

                                                    <div class="col-6">
                                                        <div class="d-grid">
                                                        <a @click="checkoutForm = false" href="javascript:void(0)" type="button" class="btn btn-light">
                                                            Cancel </a>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="d-grid">
                                                        <a href="javascript:void(0)" @click="checkout()" type="button" class="btn btn-light">
                                                            Confirm </a>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>


            <div v-if="showModal" class="modal fade show" id="myModal">
                <div
                    class="
                    modal-dialog modal-dialog-centered
                    "
                >
                    <div class="modal-content bg-dark-4 rounded-0 border-0">
                    <div class="modal-body">
                        <button
                        type="button"
                        class="btn-close float-end"
                        data-bs-dismiss="modal"
                        @click="showModal=false"
                        ></button>
                        <div class="row g-0">
                            <div class="card">
                                <div class="card-body">
                                    <div class="border p-4 rounded">
                                    <div class="text-center">
                                        <h3 class="">Sign in</h3>

                                    </div>
                                    <div class="form-body">
                                        <form class="row g-3">
                                            <div class="col-12">
                                                <label for="loginform" class="form-label">Username</label>
                                                <input v-model="username" type="text" name="username" class="form-control" id="loginform" placeholder="Username">
                                            </div>

                                            <div class="col-12">
                                                <div class="d-grid">
                                                <a href="javascript:void(0)" @click="user_name()" type="submit" class="btn btn-light">
                                                    <i class="bx bxs-lock-open"></i>Sign in </a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    </div>
                                </div>
                                </div>
                        </div>
                    </div>
                    <!--end row-->
                    </div>
                </div>
            </div>
            <!--end row-->
        </div>
         <!-- Modal Print Preview -->
            <a
              href="#printpreview"
              data-toggle="modal"
              class="account-link"
              ref="printpreview"
            ></a>
            <div v-if="showModalPrint"
              class="modal fade show"
              id="printpreview"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div
                  class="modal-content"
                  style="order-radius: 0; border-radius: 0"
                >
                  <div class="modal-header" style="border-bottom: none">
                    <button
                        type="button"
                        class="btn-close float-end"
                        data-bs-dismiss="modal"
                        @click="showModalPrint=false"
                    ></button>
                    <span
                      class="bx bx-printer"
                      @click="print_receipt()"
                      style="position: absolute; left: 46%; font-size: 25px; padding: 5px; cursor: pointer;"
                    ></span>
                  </div>
                  <div class="modal-body" v-if="printpreview">

                    <div class="row">
                      <div
                        class="col-md-12 text-center"
                        style="margin-bottom: 21px"
                      >
                        <img
                          :src="this.$parent.$parent.branchInfo.picture || 'https://ezbuy.today/images/logo-color.svg'"
                          width="100px"
                          height="50px"
                          alt="Logo"
                          style="margin-bottom: 20px"
                        />
                        <br />
                        <label for="">Name</label> :
                        <span>{{ printpreview.saleOrder.customerName }}</span>
                        <br />
                        <label for="">Date</label> :
                        <span>{{
                          printpreview.saleOrder.date.slice(0, 10)
                        }}</span>
                        <br />
                        <label for="">Reference</label> :
                        <span>{{ printpreview.saleOrder.reference }}</span>
                      </div>
                    </div>
                    <div class="saleOrder">
                      <div class="saleOrder_l">
                        <div class="clear"></div>
                      </div>
                    </div>

                    <table class="table" style="margin-top: 10px">
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Name</th>
                          <th scope="col">Price</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            item, idx
                          ) in printpreview.saleOrderTransactions"
                          :key="idx"
                        >
                          <td scope="row">{{ idx + 1 }}</td>
                          <td>{{ item.itemName }}</td>
                          <td>${{ item.price }}</td>
                          <td>{{ item.quantity }}</td>
                          <td>${{ item.amount }}</td>
                        </tr>
                        <tr>
                          <th colspan="4" style="text-align: right">Sutotal</th>
                          <th>
                            ${{ printpreview.saleOrder.grandTotalAmount }}
                          </th>
                        </tr>
                        <tr>
                          <th colspan="4" style="text-align: right">
                            Sale Tax 0%
                          </th>
                          <th>$0.00</th>
                        </tr>
                        <tr>
                          <th colspan="4" style="text-align: right">TOTAL</th>
                          <th>
                            ${{ printpreview.saleOrder.grandTotalAmount }}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!--end model Print Preview -->
    </div>
</template>
<script>
  export default {
    components: {

    },
    data() {
        return {
            currentTabComponent: "Cart",
            hideCheckOut: false,
            btnupdateOrder: false,
            items: null,
            cart: null,
            CartQty: null,
            editQty: null,
            msg: "",
            dataMyOrder: null,
            username: null,
            loginame: null,
            CheckOutForm: null,
            long: null,
            lat: null,
            address: null,
            phone: null,
            dataCheckout: null,
            idOrder: null,
            address_order: null,
            phone_order: null,
            editqtycartpopupdata: null,

            BranchId: null,
            printpreview: null,
            branchPicture: null,

            showCheckOut: false,
            showSearch: false,
            showModal: false,
            showModalPrint: false,
            classShowCart: false,
            checkoutForm: false,

            search_data: null,
            saleOrderForEdit: null,
            branchInfo: null,
        };
    },
    watch: {
        cart(value) {
            this.cart = value;
        },
        '$parent.$parent.cart'(value) {
            this.cart = value;
        },
        '$parent.$parent.loginame'(value){
            this.loginame = value;
        },
    },
    mounted() {
        if (this.$parent.$parent.cart) {
            this.cart = this.$parent.$parent.cart;
        }
        this.branchInfo = this.$parent.$parent.branchInfo;
        this.btnupdateOrder = JSON.parse(sessionStorage.getItem("btnupdateOrder"));
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition((position) => {
                this.lat = position.coords.latitude;
                this.long = position.coords.longitude;
                fetch(
                "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                    this.lat +
                    "," +
                    this.long +
                    "&key=AIzaSyB5KK9gONB5Sq5bH9e5NsBHJsBBo9njosM"
                )
                .then((response) => response.json())
                .then((data) => {
                    if (data.results.length > 0) {
                    this.address = data.results[0].formatted_address;
                    }
                });
            });
        } else {
            alert("Please Turn on your browser Location !");
        }
        // if (this.$route.params.storeId) {
        //     axios
        //         .get("/api/get_category/" + this.$route.params.storeId)
        //         .then((response) => {
        //         this.category = response.data.result;
        //         });
        // }
        if (sessionStorage.getItem("username")) {
            this.loginame = sessionStorage.getItem("username");
            // this.btnMyOrder();
        }
        this.autoLogin();
        // if (this.$route.query.BranchId) {
        //     this.BranchId = this.$route.query.BranchId;
        // }
        this.branchId = this.$parent.$parent.storeName;
    },
    methods: {
        test(){
            axios
                        .get(
                        "/api/get_printpreview/" +
                            this.branchId +
                            "/" +
                            182
                        )
                        .then((response) => {
                        this.printpreview = response.data.result;
                        this.classShowCart = false
                        this.showModalPrint = true;
                        this.checkoutForm = false;
                        // this.$refs.printpreview.click();
                        // this.$refs.closecheckuot.click();
                        });
        },
        getPreference(key){
            let contact = this.branchInfo.contact;
            let value = '';
            // value = contact.filter(x => x.key == key)[0]['value']
            contact.forEach(element => {
                if(element.key == key){
                    value = element.value;
                }
            });
            return value;
        },
        changeQty(data, $event){
            var value = $event.target.valueAsNumber;
            this.cart.item.forEach((element) => {
                if (data.name == element.name) {
                    if(element.qty < value){
                        let numer = value - element.qty;
                        element.qty = element.qty + numer;
                    }else if(element.qty > value){
                        let numer = element.qty - value;
                        element.qty = element.qty - numer;
                    }else{
                        element.qty = value;
                    }
                    // element.qty = data.qty;
                    element.quantity = data.qty;
                    if (element.qty <= 0) {
                        element.qty = 1;
                        element.quantity = 1;
                    }
                }
            });
            this.cart.totalqty = this.cart.item.reduce((a, b) => a + (b.qty || 0), 0);
            this.cart.totalprice = this.cart.item.reduce((a, b) => a + (b.qty * b.price || 0), 0);
            this.$parent.$parent.cart = this.cart;
            sessionStorage.setItem("cart", JSON.stringify(this.cart));

        },
        editQtyCart(item, numer) {
            this.cart.item.forEach((element) => {
                if (item.name == element.name) {
                    element.qty = element.qty + numer;
                    element.quantity = element.qty;
                    if (element.qty <= 0) {
                        element.qty = 1;
                        element.quantity = 1;
                    }
                }
            });
        },
        search(){
            this.$router.push("/category?search="+this.search_data);
        },
       showCart() {
            this.btnupdateOrder = JSON.parse(sessionStorage.getItem("btnupdateOrder"));
            this.classShowCart = !this.classShowCart;
        },
        closeCart() {
            this.classShowCart = false;
        },
        removeCart(id) {
            this.$parent.$parent.removeCart = id;
        },
        getQtyByBranchName() {
            let total = 0;
            this.cart.item.forEach((item) => {
                if (item.branchName == this.branchId) {
                    total+=item.qty;
                }
            });
            return total;
        },
        user_name() {
            if (this.username) {
                axios
                .get(
                    "/api/get_customer_name/" +
                    this.$parent.$parent.storeName +
                    "/" +
                    this.username
                )
                .then((response) => {
                    sessionStorage.setItem("username", response.data.result.name);
                    this.loginame = sessionStorage.getItem("username");
                    this.showModal = false;
                    // this.$refs.btnLogin.click(); // close form login
                });
            } else {
                this.msg = "Please input name!";
            }
        },
        autoLogin() {
            var CustomerName = this.$route.query.CustomerName;
            if (CustomerName) {
                axios
                .get(
                    "/api/get_customer_name/" +
                    this.$parent.$parent.storeName +
                    "/" +
                    CustomerName
                )
                .then((response) => {
                    sessionStorage.setItem("username", response.data.result.name);
                    this.loginame = sessionStorage.getItem("username");
                });
            }
        },
        checkout() {
            // find class pace-inactive and change to pace-active
            var pace = document.getElementsByClassName("pace-inactive");
            pace[0].className = "pace pace-active";

            if (this.loginame) {
                var get_cart_session = JSON.parse(sessionStorage.getItem("cart"));
                if (get_cart_session.item.length > 0) {
                var itemPost = [];
                    get_cart_session.item.forEach((element) => {
                    // validate branchId

                            itemPost = [
                            ...itemPost,
                            ...[
                                {
                                id: 0,
                                itemId: element.id,
                                description: element.description,
                                saleOrderId: 0,
                                quantity: element.qty,
                                unitMeasureId: element.unitMeasureId,
                                picture: element.picture,
                                pictureId: element.pictureId,
                                itemCode: element.code,
                                itemName: element.name,
                                unitMeasureName: element.unitMeasureName,
                                price: element.price,
                                tenancyName: this.branchId,
                                amount: element.price * element.qty,
                                },
                            ],
                        ];
                });
                const dataCheckout = {
                    saleOrder: {
                    id: null,
                    branchId: this.BranchId,
                    customerName: this.loginame,
                    shippingAddress: this.address_order,
                    memo: "",
                    },
                    saleOrderTransactions: itemPost,
                };

                axios
                    .post(
                    "/api/get_checkout/" + this.branchId,
                    dataCheckout
                    )
                    .then((response) => {
                        var newTotalPrice = 0;
                        var newTotalQty = 0;
                        this.$parent.$parent.cart.item.forEach((element) => {
                            if(element.branchName!==this.branchId){
                                newTotalPrice += element.amount;
                                newTotalQty += element.qty;
                            }

                        });
                    var idPrintPreview = response.data.result;
                    this.editQty = null;
                    this.$parent.$parent.cart = {
                        totalprice: newTotalPrice,
                        totalqty: newTotalQty,
                        item: get_cart_session.item.filter(ele=>ele.branchName != this.branchId),
                    };
                        this.cart = this.$parent.$parent.cart;

                        sessionStorage.setItem("cart", JSON.stringify(this.$parent.$parent.cart));
                    if (
                        this.order != "" &&
                        this.editQty == null &&
                        this.cart == null
                    ) {
                        this.$fire({
                        title:
                            '<span style="color:#fff">Checkout successfully!</span>',
                        type: "success",
                        background: "#000",
                        showConfirmButton: false,
                        timer: 1000,
                        });
                        this.currentTabComponent = "Cart";
                        this.phone = null;
                        this.sessionStorage.removeItem("cart");
                    }
                    var pace2 = document.getElementsByClassName("pace-active");
                    pace2[0].className = "pace pace-inactive";
                    // get print Preview
                    axios
                        .get(
                        "/api/get_printpreview/" +
                            this.branchId +
                            "/" +
                            idPrintPreview
                        )
                        .then((response) => {
                        this.printpreview = response.data.result;
                        this.classShowCart = false
                        this.showModalPrint = true;
                        this.checkoutForm = false;
                        // this.$refs.printpreview.click();
                        // this.$refs.closecheckuot.click();
                        });
                    });
                } else {
                alert("You are not select product. Please select product !"); // alert when delete product on cart (SH)
                }
            } else {
                // this.$refs.btnLogin.click();
                this.showModal = true;
                this.msg = "Please Login!";
            }
        },
        print_receipt() {
            var printContents = document.getElementById("printpreview").innerHTML;
            var originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            location.reload();
        },
        canceleditoder() {
            // check btn update order
            this.btnupdateOrder = false;
            sessionStorage.removeItem("btnupdateOrder");
            sessionStorage.removeItem("cart");
            this.cart = {
                totalprice: 0,
                totalqty: 0,
                item: [],
            };
            this.$parent.$parent.cart =this.cart;
            this.btnupdateOrder = false;
            this.closeCart();
        },
        tbn_updateOrder() {
            if (this.loginame) {
                const updateOrder = {
                    // saleOrder: {
                    //     id: this.idOrder,
                    //     tenancyName: this.branchId,
                    //     branchId: 1,
                    //     customerName: this.loginame,
                    //     shippingAddress: this.address_order,
                    //     memo: "",
                    // },
                    saleOrder: JSON.parse(sessionStorage.getItem("saleOrder")),
                    saleOrderTransactions: this.$parent.$parent.cart.item,
                };
                var pace = document.getElementsByClassName("pace-inactive");
                pace[0].className = "pace pace-active";
                axios
                .post("/api/get_checkout/" + this.branchId, updateOrder)
                .then((response) => {
                    // check btn update order
                    this.btnupdateOrder = false;
                    sessionStorage.removeItem("btnupdateOrder");
                    sessionStorage.removeItem("cart");
                    this.cart = null;
                    (this.$parent.$parent.cart = {
                    totalprice: 0,
                    totalqty: 0,
                    item: [],
                    }),
                    this.$fire({
                        title: '<span style="color:#fff">Update Order Success</span>',
                        type: "success",
                        background: "#000",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                });
                var pace2 = document.getElementsByClassName("pace-active");
                pace2[0].className = "pace pace-inactive";
            } else {
                this.$refs.btnLogin.click();
            }
        },
        logout() {
            sessionStorage.removeItem("username");
            sessionStorage.removeItem("myOrder");
            sessionStorage.removeItem("cart");
            sessionStorage.removeItem("btnupdateOrder");
            this.btnupdateOrder = false;
            this.loginame = null;
            this.username = "";
            this.$parent.phone = "";
            this.editQty = null;
            this.cart = null;
            this.$parent.$parent.cart = {
                totalprice: 0,
                totalqty: 0,
                item: [],
            };
            window.location.replace(window.location.href);
        },
    },

  };
</script>
<style scoped>
.cart-list {
    overflow-y: scroll;
}
.mr-2{
    margin-right: 8px;
}
.alert-count-cus {
    position: absolute;
    top: 10px;
    left: 26px;
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
    background: red;
    padding: 4px;
    padding-bottom: 2px;
}
.dropdown-large .cart-product-cancel
{
    background-color: rgb(255 0 0 / 70%);
}
</style>
