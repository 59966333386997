<template>
    <!--start page wrapper -->
    <div class="page-wrapper">
        <div class="page-content">
            <!--start breadcrumb-->
            <section class="py-3 border-bottom d-none d-md-flex">
                <div class="container">
                    <div class="page-breadcrumb d-flex align-items-center">
                        <h3 class="breadcrumb-title pe-3">Reset Password</h3>
                        <div class="ms-auto">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0 p-0">
                                    <li class="breadcrumb-item"><router-link to="/"><i class="bx bx-home-alt"></i> Home</router-link>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">Reset Password</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <!--end breadcrumb-->
            <!--start shop cart-->
            <section class="">
                <div class="container">
                    <div class="d-flex justify-content-center my-lg-4">
                        <div class="card forgot-box">
                            <div class="card-body">

                                <div class="p-4 rounded  border">
                                    <div class="text-center">
                                        <h4 class="font-weight-bold">Reset Password</h4>
                                        <p class="" style="width: 380px;"></p>
                                    </div>

                                    <div class="login-separater text-center mb-4 mt-4">
                                        <hr/>
                                    </div>
                                    <div v-if="verifyToken" class="my-4">
                                        <h5>Verify authentication</h5>
                                        <p>Please wail...</p>

                                    </div>
                                    <div v-else-if="somethingWhenWrong">
                                        <div class="my-4">
                                            <h5>Sorry, your token expired!</h5>
                                            <p>We'll need to re-send your authentication email</p>

                                        </div>
                                        <div class="d-grid gap-2">
                                            <button @click="$router.push('/forgot-password')" type="button" class="btn btn-light">Forgot Password</button>
                                        </div>
                                    </div>
                                    <div v-else-if="isVerify">
                                        <div class="my-4">
                                            <label class="form-label">New Password</label>
                                            <input  v-model="form.newPassword" type="password" min="6" required class="form-control"/>
                                        </div>
                                        <div class="my-4">
                                            <label class="form-label">Confirm Password</label>
                                            <input  v-model="form.confirmPassword" type="password" min="6" required class="form-control"/>
                                        </div>
                                        <div class="d-grid gap-2">
                                            <button @click="doSubmit()" type="button" class="btn btn-light">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--end shop cart-->
        </div>
    </div>
    <!--end page wrapper -->
        </template>
        <script>

        export default {
            components: {

            },
            data() {
                return {
                    verifyToken: true,
                    somethingWhenWrong: false,
                    isVerify: false,

                    form: {
                        newPassword: "",
                        confirmPassword: "",
                    },

                    newToken: null,
                };
            },
            mounted() {
                this.doVerify();
            },
            methods: {
                doVerify(){
                    if(this.$route.query.token){
                        axios.get("/api/verify-token?token="+this.$route.query.token)
                            .then((response)=>{
                                console.log(response.data);
                                if(response.data.status == 1){
                                    this.newToken = response.data.token;
                                    this.isVerify = true;
                                }else{
                                    this.somethingWhenWrong = true;
                                }
                            })
                    }else{
                        this.somethingWhenWrong = true;
                    }
                    this.verifyToken = false;
                },
                doSubmit() {
                    if(!this.form.newPassword || !this.form.confirmPassword){
                        this.$swal.fire({
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            toast: true,
                            position: "top-end",
                            background: "rgb(11 70 49)",
                            icon: "error",

                            title: '<span style="color:#fff">Please fill out<span>',
                        });
                        return
                    }
                    if(this.form.newPassword != this.form.confirmPassword){
                        this.$swal.fire({
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            toast: true,
                            position: "top-end",
                            background: "rgb(11 70 49)",
                            icon: "error",

                            title: '<span style="color:#fff">Password and confirmation password do not match.<span>',
                        });
                        return;
                    }
                    if(this.form.newPassword.length < 6){
                        this.$swal.fire({
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            toast: true,
                            position: "top-end",
                            background: "rgb(11 70 49)",
                            icon: "error",

                            title: '<span style="color:#fff">Password must be at least 6 characters<span>',
                        });
                        return;
                    }
                    if(this.newToken){

                        var pace = document.getElementsByClassName("pace-inactive");
                        pace[0].className = "pace pace-active";

                        let fd = new FormData();
                        fd.append("new_password", this.form.newPassword);
                        fd.append("confirm_password", this.form.confirmPassword);
                        fd.append("token", this.newToken);
                        axios.post("/api/reset-password", fd).then((response)=>{
                            if(response.data.status == 1){
                                this.$swal.fire({
                                    showConfirmButton: false,
                                    timer: 4000,
                                    timerProgressBar: true,
                                    toast: true,
                                    position: "top-end",
                                    background: "rgb(11 70 49)",
                                    icon: "success",

                                    title: '<span style="color:#fff">'+response.data.message+'<span>',
                                });

                                this.form.newPassword = "";
                                this.form.confirmPassword = "";

                                setTimeout(() => {
                                    this.$router.push("/signin");
                                }, 4000);
                            }else{
                                this.$swal.fire({
                                    showConfirmButton: false,
                                    timer: 5000,
                                    timerProgressBar: true,
                                    toast: true,
                                    position: "top-end",
                                    background: "rgb(11 70 49)",
                                    icon: "error",

                                    title: '<span style="color:#fff">'+response.data.message+'<span>',
                                });
                            }
                        }).catch((error)=>{

                        });

                        var pace2 = document.getElementsByClassName("pace-active");
                        pace2[0].className = "pace pace-inactive";
                    }

                }
            },
        };
        </script>

        <style scoped>
        </style>
