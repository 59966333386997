<template>
    <div class="header-wrapper bg-dark-1">
        <TopHeader />
        <HeaderContent />
        <PrimaryMenu />
    </div>
</template>
<script>
import TopHeader from './header/TopMenu.vue';
import HeaderContent from './header/HeaderContent.vue';
import PrimaryMenu from './header/PrimaryMenu.vue';

export default {
    components: {
        TopHeader: TopHeader,
        HeaderContent: HeaderContent,
        PrimaryMenu: PrimaryMenu,
    },
    data() {
        return {

        };
    },
    mounted() {

    },
    methods: {

    },
    watch: {

    },
};
</script>
<style scoped>

</style>
