<template>
    <section class="py-4">
        <div class="container">
            <div class="d-flex align-items-center">
                <h5 class="text-uppercase mb-0">FEATURED PRODUCTS</h5>
                <router-link to="/category?name=All" class="btn btn-light ms-auto rounded-0">More Products<i class='bx bx-chevron-right'></i></router-link>
            </div>
            <hr/>
            <div class="product-grid">
                <div v-if="products" class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                    <div v-for="(item, idx) in products" :key="idx">
                        <ProductThumb :storeName="$parent.$parent.storeName" :item="item" :key="idx" width="100%" />
                    </div>
                </div>
                <!--end row-->
            </div>
        </div>
        <ModalQuickViewProduct v-if="modalProduct" @close="closeModal()" :product="modalProduct" />
    </section>
</template>
<script>
import ProductThumb from "../ProductThumb.vue";
import ModalQuickViewProduct from "../ModalQuickViewProduct.vue";
export default {
  components: {
    ProductThumb,
    ModalQuickViewProduct,
  },
  data() {
    return {
      products: null,
      modalProduct: null,
    };
  },
  mounted() {
    this.getProductTopSale();
    // this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
    //   console.log('Modal is about to be shown', bvEvent, modalId)
    // })
  },
  methods: {
    getProductTopSale() {
        axios.get("/api/get_home_product?store="+this.$parent.$parent.storeName).then((response) => {
            this.products = response.data.result.slice(0, 4);
        });
    },
    showModal(value){
        if(value){
            this.modalProduct = value;
        }
    },
    closeModal(){
        this.modalProduct = null;
        document.querySelector(".modal-backdrop").remove();
        document.querySelector("body").classList.remove("modal-open");
    }
  },
};
</script>
<style scoped>

</style>
