<template>
    <section class="py-4 bg-dark-1">
        <div class="container">
            <div v-if="info" class="row row-cols-1 row-cols-md-2 row-cols-xl-4 row-group">
                <div class="col">
                    <div class="text-center">
                        <div class="font-50 text-white">	<i class='bx bx-cart'></i>
                        </div>
                        <h2 class="fs-5 text-uppercase mb-0">Free delivery</h2>
                        <p class="text-capitalize" v-html="getPreference('Free delivery')"></p>
                    </div>
                </div>
                <div class="col">
                    <div class="text-center">
                        <div class="font-50 text-white">	<i class='bx bx-credit-card'></i>
                        </div>
                        <h2 class="fs-5 text-uppercase mb-0">Secure payment</h2>
                        <p class="text-capitalize" v-html="getPreference('Secure payment')"></p>
                    </div>
                </div>
                <div class="col">
                    <div class="text-center">
                        <div class="font-50 text-white">	<i class='bx bx-dollar-circle'></i>
                        </div>
                        <h2 class="fs-5 text-uppercase mb-0">Free returns</h2>
                        <p class="text-capitalize" v-html="getPreference('Free returns')"></p>
                    </div>
                </div>
                <div class="col">
                    <div class="text-center">
                        <div class="font-50 text-white">	<i class='bx bx-support'></i>
                        </div>
                        <h2 class="fs-5 text-uppercase mb-0">Customer Support</h2>
                        <p class="text-capitalize" v-html="getPreference('Customer Support')"></p>
                    </div>
                </div>
            </div>
            <!--end row-->
        </div>
    </section>
</template>
<script>
  export default {
    components: {

    },
    data() {
      return {
        info: null,
      };
    },
    mounted() {
        this.info = this.$parent.$parent.branchInfo?.support_info;
    },
    methods: {
        getPreference(key){
            let data = this.info;
            let value = '';
            // value = contact.filter(x => x.key == key)[0]['value']
            data.forEach(element => {
                if(element.key == key){
                    value = element.value;
                }
            });
            return value;
        }
    },
    watch: {

    },
  };
</script>
<style scoped>

</style>
