<template>
<!--start page wrapper -->
<div class="page-wrapper">
    <div class="page-content">
        <!--start breadcrumb-->
        <section class="py-3 border-bottom d-none d-md-flex">
            <div class="container">
                <div class="page-breadcrumb d-flex align-items-center">
                    <h3 class="breadcrumb-title pe-3">Sign Up</h3>
                    <div class="ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb mb-0 p-0">
                                <li class="breadcrumb-item"><router-link to="/"><i class="bx bx-home-alt"></i> Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Sign Up</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <!--end breadcrumb-->
        <!--start shop cart-->
        <section class="">
            <div class="container">
                <div class="section-authentication-signin d-flex justify-content-center my-5 my-lg-4">
                    <div class="row row-cols-1 row-cols-lg-1 row-cols-xl-2">
                        <div class="col mx-auto">
                            <div class="card mb-0">
                                <div class="card-body">
                                    <div class="border p-4 rounded">
                                        <div class="text-center">
                                            <h3 class="">Sign Up</h3>
                                            <p>Already have an account? <router-link to="/signin">Sign in here</router-link>
                                            </p>
                                        </div>
                                        <div class="login-separater text-center mb-4 mt-4">
                                            <hr/>
                                        </div>
                                        <div class="form-body">
                                            <form @submit.prevent="register" class="row g-3">
                                                <!-- <div class="col-sm-6">
                                                    <label for="inputFirstName" class="form-label">First Name</label>
                                                    <input v-model="first_name" type="text" class="form-control" id="inputFirstName" placeholder="Jhon">
                                                </div> -->
                                                <div class="col-sm-12">
                                                    <label for="name" class="form-label">Username</label>
                                                    <input v-model="form.name" type="text" class="form-control" id="name" placeholder="Dara">
                                                </div>
                                                <div class="col-12">
                                                    <label for="inputEmailAddress" class="form-label">Email Address</label>
                                                    <input v-model="form.email" type="email" class="form-control" id="inputEmailAddress" placeholder="example@user.com">
                                                </div>
                                                <div class="col-12">
                                                    <label for="inputChoosePassword" class="form-label">Password</label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input v-model="form.password" :type="isShowPassword?'text':'password'" class="form-control border-end-0" id="inputChoosePassword" value="" placeholder="Enter Password">
                                                        <a @click="showPassword()" href="javascript:;"  class="input-group-text bg-transparent"><i class='bx' :class="isShowPassword?'bx-show':'bx-hide'"></i></a>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-12">
                                                    <label for="inputSelectCountry" class="form-label">Country</label>
                                                    <select class="form-select" id="inputSelectCountry" aria-label="Default select example">
                                                        <option selected>India</option>
                                                        <option value="1">United Kingdom</option>
                                                        <option value="2">America</option>
                                                        <option value="3">Dubai</option>
                                                    </select>
                                                </div> -->
                                                <!-- <div class="col-12">
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked">
                                                        <label class="form-check-label" for="flexSwitchCheckChecked">I read and agree to Terms & Conditions</label>
                                                    </div>
                                                </div> -->
                                                <div class="col-12">
                                                    <div class="d-grid">
                                                        <button type="submit" class="btn btn-light"><i class='bx bx-user'></i>Sign up</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end row-->
                </div>
            </div>
        </section>
        <!--end shop cart-->
    </div>
</div>
<!--end page wrapper -->
</template>
<script>

export default {
    components: {

    },
    data() {
        return {
            branchInfo: null,

            name: null,
            phone: null,
            mail: null,
            content: null,
            isShowPassword: false,
            form:{
                // first_name: null,
                name: null,
                email: null,
                password: null,
            }
        };
    },
    mounted() {

    },
    methods: {
        showPassword() {
            this.isShowPassword = !this.isShowPassword;
        },
        register() {

            var pace = document.getElementsByClassName("pace-inactive");
            pace[0].className = "pace pace-active";

            let fd = new FormData();
            // fd.append("first_name", this.form.first_name);
            fd.append("name", this.form.name);
            fd.append("email", this.form.email);
            fd.append("password", this.form.password);
            axios.get("/sanctum/csrf-cookie").then((response) => {
                axios.post("/api/register", fd).then((response2)=>{
                    if(response2?.data?.status == 1){
                        this.$swal.fire({
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            toast: true,
                            position: "top-end",
                            background: "rgb(11 70 49)",
                            icon: "success",

                            title: '<span style="color:#fff">'+response2.data.message+'<span>',
                        });
                        this.$router.push('/signin');
                    }else if(response2?.data?.message){
                        this.$swal.fire({
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            toast: true,
                            position: "top-end",
                            background: "rgb(11 70 49)",
                            icon: "error",

                            title: '<span style="color:#fff">'+response2.data.message+'<span>',
                        });
                    }
                });

            });
            var pace2 = document.getElementsByClassName("pace-active");
            pace2[0].className = "pace pace-inactive";
        },
        handleContact() {
            if (this.name && this.phone && this.mail && this.content) {
                axios
                .post("/api/post_contact", {
                    name: this.name,
                    phone: this.phone,
                    mail: this.mail,
                    content: this.content,
                })
                .then((response) => {
                    if (response.data.success) {
                    this.name = null;
                    this.phone = null;
                    this.email = null;
                        this.content = null;
                        this.$swal.fire({
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        toast: true,
                        position: "top-end",
                        background: "rgb(11 70 49)",
                        icon: "success",
                        title: '<span style="color:#fff">Your Contact has been submited!<span>',
                    });
                    }

                });
            }
            },
    },
};
</script>

<style scoped>
</style>
