<template>
  <div class="page-wrapper">
			<div class="page-content">
				<!--start breadcrumb-->
				<section class="py-3 border-bottom d-none d-md-flex">
					<div class="container">
						<div class="page-breadcrumb d-flex align-items-center">
							<h3 class="breadcrumb-title pe-3">Contact Us</h3>
							<div class="ms-auto">
								<nav aria-label="breadcrumb">
									<ol class="breadcrumb mb-0 p-0">
										<li class="breadcrumb-item"><router-link to="/"><i class="bx bx-home-alt"></i> Home</router-link>
										</li>
										<li class="breadcrumb-item active" aria-current="page">Contact Us</li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
				</section>
                <!--end breadcrumb-->
				<!--start page content-->
				<section class="py-4">
					<div class="container">
						<h3 class="d-none">Google Map</h3>
						<div v-if="branchInfo" class="contact-map p-3 bg-dark-1 rounded-0 shadow-none">
							<div v-html="getPreference('Map')"></div>
						</div>
					</div>
				</section>
				<section class="py-4">
					<div class="container">
						<div class="row">
							<div class="col-lg-8">
								<div class="p-3 bg-dark-1">
									<form @submit.prevent="handleContact()">
										<div class="form-body">
											<h6 class="mb-0 text-uppercase">Drop us a line</h6>
											<div class="my-3 border-bottom"></div>
											<div class="mb-3">
												<label class="form-label">Enter Your Name</label>
												<input v-model="name" required type="text" class="form-control" />
											</div>
											<div class="mb-3">
												<label class="form-label">Enter Email</label>
												<input v-model="mail" required type="text" class="form-control" />
											</div>
											<div class="mb-3">
												<label class="form-label">Phone Number</label>
												<input v-model="phone" required type="number" class="form-control" />
											</div>
											<div class="mb-3">
												<label class="form-label">Message</label>
												<textarea v-model="content" required class="form-control" rows="4" cols="4"></textarea>
											</div>
											<div class="mb-3">
												<button class="btn btn-light btn-ecomm">Send Message</button>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div class="col-lg-4">
								<div v-if="branchInfo" class="p-3 bg-dark-1">
									<div class="address mb-3">
										<p class="mb-0 text-uppercase text-white">Address</p>
										<p class="mb-0 font-12">{{ getPreference("Address") }}</p>
									</div>
									<div class="phone mb-3">
										<p class="mb-0 text-uppercase text-white">Phone</p>
										<p class="mb-0 font-13">Mobile : {{ getPreference('Phone') }}</p>
									</div>
									<div class="email mb-3">
										<p class="mb-0 text-uppercase text-white">Email</p>
										<p class="mb-0 font-13">{{ getPreference('Email') }}</p>
									</div>
									<div class="working-days mb-3">
										<p class="mb-0 text-uppercase text-white">WORKING DAYS</p>
										<p class="mb-0 font-13">{{ getPreference('Work Day') }}</p>
									</div>
								</div>
							</div>
						</div>
						<!--end row-->
					</div>
				</section>
				<!--end start page content-->




        </div>
    </div>
</template>
<script>

export default {
    components: {

    },
    data() {
        return {
            branchInfo: null,

            name: null,
            phone: null,
            mail: null,
            content: null,
        };
    },
    mounted() {
        this.branchInfo = this.$parent.branchInfo;
    },
    methods: {
        getPreference(key) {
            let contact = this.branchInfo.contact;
            let value = '';
            contact.forEach(element => {
                if (element.key == key) {
                    value = element.value;
                }
            });
            return value;
        },
        handleContact() {
            if (this.name && this.phone && this.mail && this.content) {
                var pace = document.getElementsByClassName("pace-inactive");
                pace[0].className = "pace pace-active";
                axios
                .post("/api/post_contact", {
                    name: this.name,
                    phone: this.phone,
                    mail: this.mail,
                    content: this.content,
                })
                .then((response) => {
                    if (response.data.success) {
                    this.name = null;
                    this.phone = null;
                    this.email = null;
                        this.content = null;
                        this.$swal.fire({
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        toast: true,
                        position: "top-end",
                        background: "rgb(11 70 49)",
                        icon: "success",
                        title: '<span style="color:#fff">Your Contact has been submited!<span>',
                    });
                    }

                });

                var pace2 = document.getElementsByClassName("pace-active");
                pace2[0].className = "pace pace-inactive";
            }
            },
    },
};
</script>

<style scoped>
</style>
