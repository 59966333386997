<template>
<!--start page wrapper -->
<div class="page-wrapper">
    <div class="page-content">
        <!--start breadcrumb-->
        <section class="py-3 border-bottom d-none d-md-flex">
            <div class="container">
                <div class="page-breadcrumb d-flex align-items-center">
                    <h3 class="breadcrumb-title pe-3">Sign in</h3>
                    <div class="ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb mb-0 p-0">
                                <li class="breadcrumb-item"><router-link to="/"><i class="bx bx-home-alt"></i> Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Sign In</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <!--end breadcrumb-->
        <!--start shop cart-->
        <section class="">
            <div class="container">
                <div class="section-authentication-signin d-flex justify-content-center my-5 my-lg-4">
                    <div class="row row-cols-1 row-cols-xl-2">
                        <div class="col mx-auto">
                            <div class="card">
                                <div class="card-body">
                                    <div class="border p-4 rounded">
                                        <div class="text-center">
                                            <h3 class="">Sign in</h3>
                                            <p>Don't have an account yet? <router-link to="/signup">Sign up here</router-link>
                                            </p>
                                        </div>
                                        <div class="login-separater text-center mb-4 mt-4">
                                            <hr/>
                                        </div>
                                        <div class="form-body">
                                            <form @submit.prevent="checkLogin" class="row g-3">
                                                <!-- <div class="col-12">
                                                    <label for="inputEmailAddress" class="form-label">Email Address</label>
                                                    <input type="email" v-model="form.email" class="form-control" id="inputEmailAddress" placeholder="Email Address">
                                                </div> -->
                                                <div class="col-12">
                                                    <label for="inputEmailAddress" class="form-label">Username</label>
                                                    <input type="text" v-model="form.name" class="form-control" id="inputEmailAddress" placeholder="Username">
                                                </div>
                                                <div class="col-12">
                                                    <label for="inputChoosePassword" class="form-label">Enter Password</label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input v-model="form.password" :type="isShowPassword?'text':'password'" class="form-control border-end-0" id="inputChoosePassword" value="" placeholder="Enter Password">
                                                        <a @click="showPassword()" href="javascript:;" class="input-group-text bg-transparent"><i class='bx' :class="isShowPassword?'bx-show':'bx-hide'"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked>
                                                        <label class="form-check-label" for="flexSwitchCheckChecked">Remember Me</label>
                                                    </div>
                                                </div>
                                                <div style="" class="col-md-6 text-end">	<router-link to="/forgot-password">Forgot Password ?</router-link>
                                                </div>
                                                <div style="" class="col-12">
                                                    <div class="d-grid">
                                                        <button type="submit" class="btn btn-light"><i class="bx bxs-lock-open"></i>Sign in</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end row-->
                </div>
            </div>
        </section>
        <!--end shop cart-->
    </div>
</div>
<!--end page wrapper -->
</template>
<script>

export default {
    components: {

    },
    data() {
        return {
            branchInfo: null,

            name: null,
            phone: null,
            mail: null,
            content: null,
            form:{
                email: null,
                name: null,
                password: null,
            },
            isShowPassword: false,
        };
    },
    mounted() {
        this.branchInfo = this.$parent.branchInfo;
    },
    methods: {
        showPassword() {
            this.isShowPassword = !this.isShowPassword;
        },
        checkLogin() {

            var pace = document.getElementsByClassName("pace-inactive");
            pace[0].className = "pace pace-active";

            let fd = new FormData();
            fd.append("name", this.form.name);
            fd.append("password", this.form.password);
            axios.get("/sanctum/csrf-cookie").then((response) => {
                axios.post("/api/login", fd).then((response2)=>{
                    if(response2?.data?.token){
                        sessionStorage.setItem("access_token", response2?.data?.token);
                        // axios.get(
                        //     "/api/test/aa",
                        //     {
                        //         headers: {
                        //             Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
                        //         }
                        //     }
                        // ).then((response)=>{
                        //     console.log(response);
                        // });

                        var CustomerName = this.form.name;
                        if (CustomerName) {
                            axios
                            .get(
                                "/api/get_customer_name/" +
                                this.$parent.storeName +
                                "/" +
                                CustomerName
                            )
                            .then((response3) => {
                                sessionStorage.setItem("username", response3.data.result.name);
                                this.$parent.loginame = sessionStorage.getItem("username");
                                setTimeout(() => {
                                    // this.$router.push('/');
                                    window.location.href = '/';
                                }, 200);

                            });
                        }
                    }else if(response2?.data?.message){
                        this.$swal.fire({
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            toast: true,
                            position: "top-end",
                            background: "rgb(11 70 49)",
                            icon: "error",

                            title: '<span style="color:#fff">'+response2.data.message+'<span>',
                        });
                    }
                });

            });
            var pace2 = document.getElementsByClassName("pace-active");
            pace2[0].className = "pace pace-inactive";
        },
    },
};
</script>

<style scoped>
</style>
