<template>
    <section class="py-4">
        <div class="container">
            <div class="d-flex align-items-center">
                <h5 class="text-uppercase mb-0">Browse Catergory</h5>
                <a href="shop-categories.html" class="btn btn-light ms-auto rounded-0">View All<i class='bx bx-chevron-right'></i></a>
            </div>
            <hr/>
            <div class="product-grid">
                <div class="browse-category owl-carousel owl-theme">
                    <div class="item">
                        <div class="card rounded-0 product-card border">
                            <div class="card-body">
                                <img src="/assets_store_emmeline/assets/images/categories/01.png" class="img-fluid" alt="...">
                            </div>
                            <div class="card-footer text-center">
                                <h6 class="mb-1 text-uppercase">Fashion</h6>
                                <p class="mb-0 font-12 text-uppercase">10 Products</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card rounded-0 product-card border">
                            <div class="card-body">
                                <img src="/assets_store_emmeline/assets/images/categories/02.png" class="img-fluid" alt="...">
                            </div>
                            <div class="card-footer text-center">
                                <h6 class="mb-1 text-uppercase">Watches</h6>
                                <p class="mb-0 font-12 text-uppercase">8 Products</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card rounded-0 product-card border">
                            <div class="card-body">
                                <img src="/assets_store_emmeline/assets/images/categories/03.png" class="img-fluid" alt="...">
                            </div>
                            <div class="card-footer text-center">
                                <h6 class="mb-1 text-uppercase">Shoes</h6>
                                <p class="mb-0 font-12 text-uppercase">14 Products</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card rounded-0 product-card border">
                            <div class="card-body">
                                <img src="/assets_store_emmeline/assets/images/categories/04.png" class="img-fluid" alt="...">
                            </div>
                            <div class="card-footer text-center">
                                <h6 class="mb-1 text-uppercase">Bags</h6>
                                <p class="mb-0 font-12 text-uppercase">6 Products</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card rounded-0 product-card border">
                            <div class="card-body">
                                <img src="/assets_store_emmeline/assets/images/categories/05.png" class="img-fluid" alt="...">
                            </div>
                            <div class="card-footer text-center">
                                <h6 class="mb-1 text-uppercase">Electronis</h6>
                                <p class="mb-0 font-12 text-uppercase">6 Products</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card rounded-0 product-card border">
                            <div class="card-body">
                                <img src="/assets_store_emmeline/assets/images/categories/06.png" class="img-fluid" alt="...">
                            </div>
                            <div class="card-footer text-center">
                                <h6 class="mb-1 text-uppercase">Headphones</h6>
                                <p class="mb-0 font-12 text-uppercase">5 Products</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card rounded-0 product-card border">
                            <div class="card-body">
                                <img src="/assets_store_emmeline/assets/images/categories/07.png" class="img-fluid" alt="...">
                            </div>
                            <div class="card-footer text-center">
                                <h6 class="mb-1 text-uppercase">Furniture</h6>
                                <p class="mb-0 font-12 text-uppercase">20 Products</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card rounded-0 product-card border">
                            <div class="card-body">
                                <img src="/assets_store_emmeline/assets/images/categories/08.png" class="img-fluid" alt="...">
                            </div>
                            <div class="card-footer text-center">
                                <h6 class="mb-1 text-uppercase">Jewelry</h6>
                                <p class="mb-0 font-12 text-uppercase">16 Products</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card rounded-0 product-card border">
                            <div class="card-body">
                                <img src="/assets_store_emmeline/assets/images/categories/09.png" class="img-fluid" alt="...">
                            </div>
                            <div class="card-footer text-center">
                                <h6 class="mb-1 text-uppercase">Sports</h6>
                                <p class="mb-0 font-12 text-uppercase">28 Products</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card rounded-0 product-card border">
                            <div class="card-body">
                                <img src="/assets_store_emmeline/assets/images/categories/10.png" class="img-fluid" alt="...">
                            </div>
                            <div class="card-footer text-center">
                                <h6 class="mb-1 text-uppercase">Vegetable</h6>
                                <p class="mb-0 font-12 text-uppercase">15 Products</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card rounded-0 product-card border">
                            <div class="card-body">
                                <img src="/assets_store_emmeline/assets/images/categories/11.png" class="img-fluid" alt="...">
                            </div>
                            <div class="card-footer text-center">
                                <h6 class="mb-1 text-uppercase">Medical</h6>
                                <p class="mb-0 font-12 text-uppercase">24 Products</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card rounded-0 product-card border">
                            <div class="card-body">
                                <img src="/assets_store_emmeline/assets/images/categories/12.png" class="img-fluid" alt="...">
                            </div>
                            <div class="card-footer text-center">
                                <h6 class="mb-1 text-uppercase">Sunglasses</h6>
                                <p class="mb-0 font-12 text-uppercase">18 Products</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
  export default {
    components: {

    },
    data() {
      return {

      };
    },
    mounted() {

    },
    methods: {

    },
    watch: {

    },
  };
</script>
<style scoped>

</style>
