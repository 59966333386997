import { render, staticRenderFns } from "./SupportInfo.vue?vue&type=template&id=0541a558&scoped=true&"
import script from "./SupportInfo.vue?vue&type=script&lang=js&"
export * from "./SupportInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0541a558",
  null
  
)

export default component.exports