<template>
    <div class="page-wrapper">
			<div v-if="data != null" class="page-content">
				<!--start breadcrumb-->
				<section class="py-3 border-bottom d-none d-md-flex">
					<div class="container">
						<div class="page-breadcrumb d-flex align-items-center">
							<h3 class="breadcrumb-title pe-3">{{ data.name }}</h3>
							<div class="ms-auto">
								<nav aria-label="breadcrumb">
									<ol class="breadcrumb mb-0 p-0">
										<li class="breadcrumb-item"><router-link to="/"><i class="bx bx-home-alt"></i> Home</router-link>
										</li>
										<li class="breadcrumb-item active" aria-current="page">Product Details</li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
				</section>
                <section class="py-4">
					<div class="container">
						<div class="product-detail-card">
							<div class="product-detail-body">
								<div class="row g-0">
									<div class="col-12 col-lg-5">
										<div class="image-zoom-section">
											<div class="product-gallery owl-carousel owl-theme border mb-3 p-3" style="display:block;">

												<img style="max-width: 100%" v-bind:src="data.picture ||
                                                    'https://acc.ksoft.solutions/Common/Images/default-item-picture.png'
                                                    "
                                                    ref="images"
                                                />
											</div>

										</div>
									</div>
									<div class="col-12 col-lg-7">
										<div class="product-info-section p-3">
											<div class="product-featured-info">
                                                <h5>Name : {{ data.name }}</h5>
                                                <h5>Category : {{ data.categoryName }}</h5>
                                                <h5>Code : {{ data.code }}</h5>
                                                <div class="info-price">
                                                    <h5>
                                                        <span>Price : {{ data.price +" "+ data.currency}}<span v-if="data.regularPrice>0" :style="{color:'gray',textDecoration:'line-through',paddingLeft:'15px'}">{{data.regularPrice +" "+ data.currency}}</span><span :style="{padding:'0 5px'}" v-if="data.savePrice">|</span><span v-if="data.savePrice" :style="{color:'red',fontWeight:'600'}">{{data.savePrice}}</span></span>
                                                    </h5>
                                                </div>
                                                <h6 :style="{color:'#808080',textTransform:'capitalize'}">
                                                    <span>{{data.available }} {{data.available>1?'Available':data.availabel==0?'Not Availabel':'Availables'}} </span> <span :style="{padding:'0 5px'}">|</span><span v-if="data.sold>0">{{data.sold}}{{data.sold>1?' Orders':' Order'}}</span>
                                                </h6>
                                                <div class="product-featured-info">
                                                    <carousel class="bx-pager">
                                                    <a
                                                        v-for="(item, idx) in data.itemPictures"
                                                        :key="idx"
                                                        :data-slide-index="idx"
                                                        href="javascript:;"
                                                        ><slide
                                                        ><img
                                                            :src="item.picture"
                                                            @click="setNewImage" /></slide
                                                    ></a>
                                                    </carousel>
                                                </div>
                                                <div class="row row-cols-auto align-items-center mt-3">
                                                    <div class="col" style="">
                                                        <label class="form-label">Quantity</label>
                                                        <!-- <select v-model="CartQty" class="form-select form-select-sm">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                        </select> -->
                                                        <br />
                                                        <input v-model="CartQty" min="1" style="font-size:18px; width: 88px; height: 31px;" type="number" />
                                                    </div>
                                                    <div class="col" style="">
                                                        <a href="javascript:;" @click="AddToCart(data)" class="btn btn-white btn-ecomm" style="margin-top: 28px; padding: 5px; border-radius: 2px;"><i class="bx bxs-cart-add"></i>Add to Cart</a>
                                                    </div>
                                                </div>

                                            </div>
										</div>
									</div>
                                    <div class="col-12 col-lg-12">
                                        <div class="mt-3">
                                            <h5>Discription :</h5>
                                            <div v-html="data.description"></div>
                                        </div>
                                    </div>
								</div>
								<!--end row-->
							</div>
						</div>
					</div>
				</section>
            </div>
    </div>
</template>
  <script>
  import { Carousel, Slide } from "vue-carousel";
  export default {
    components: {
      Carousel,
      Slide,
    },
    data() {
      return {
        data: null,
        CartQty: 1,
      };
    },
    mounted() {
      var dateFilter = this.$parent.items.filter(
        (elment) => elment.id == this.$route.params.id
      );
      this.data = dateFilter[0];
      this.display = this.items;
      setTimeout(() => {
        // this.$refs.images.style.width = "100%";
      }, 100);
    },
    methods: {
      setNewImage($event) {
        var src = $event.target.src;
        this.$refs.images.src = src;
      },

      AddToCart(item) {
        if(this.CartQty <=0){
            this.CartQty = 1;
        }
        const dataItem = { ...item, ...{ qty: this.CartQty,branchName:this.$parent.storeName } };
        this.$parent.itemAdd = dataItem;
        this.$swal.fire({
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          toast: true,
          position: "top-end",
          background: "rgb(11 70 49)",
          icon: "success",

          title: '<span style="color:#fff">Item Added!<span>',
        });
        document.querySelector(".swal2-backdrop-show").style.zIndex = 99999999;
      },
      AddQtyCart(number) {
        this.CartQty += number;
        if (this.CartQty <= 0) {
          this.CartQty = 1;
        }
      },
    },
    watch: {
      CartQty(value) {
        // if (value <= 0) {
        //     value = 1;
        // }
        this.CartQty = parseInt(value);
      },
    },
  };
  </script>
  <style>
  .product-featured-info .bx-pager a {
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px;
  }
  .product-featured-info .bx-pager {
    margin-top: 20px;
  }
  .product-featured-info .bx-pager img {
    width: 100%;
  }
  .cm_img {
    display: flex;
    align-items: center;
  }
  @media (max-width: 414px) {
    .cm_img {
      display: block;
    }
  }
  </style>
