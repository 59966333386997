import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from "./pages/Home.vue";
import DetailItem from "./pages/Detail.vue";

import Order from "./pages/Order.vue";
import Account from "./pages/Account.vue";

import Contact from "./pages/ContactUs.vue";
import About from "./pages/About.vue";

import Signin from "./pages/Signin.vue";
import Signup from "./pages/Signup.vue";
import ForgotPassword from "./pages/ForgotPassword.vue";
import ResetPassword from "./pages/ResetPassword.vue";

import ProductByCategory from "./pages/ProductByCategory.vue";

import Blog from "./pages/Blog.vue";
import BlogDetail from "./pages/BlogDetail.vue";

Vue.use(VueRouter);

export default new VueRouter({
    routes: [
        { path: "/", component: Home },

        { path: "/order", component: Order },
        { path: "/account", component: Account },

        { path: "/about", component: About },
        { path: "/contact", component: Contact },
        { path: "/category", component: ProductByCategory },

        { path: "/signin", component: Signin },
        { path: "/signup", component: Signup },
        { path: "/forgot-password", component: ForgotPassword },
        { path: "/reset-password", component: ResetPassword },

        { path: "/productDetail/:id", component: DetailItem },
        { path: "/home", component: Home },

        { path: "/blog", component: Blog },
        { path: "/blog/:id", component: BlogDetail }
    ],
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 }
    }

});
