<template>
  <div class="page-wrapper">
			<div class="page-content">
				<!--start breadcrumb-->
				<section class="py-3 border-bottom d-none d-md-flex">
					<div class="container">
						<div class="page-breadcrumb d-flex align-items-center">
							<h3 class="breadcrumb-title pe-3">My Orders</h3>
							<div class="ms-auto">
								<nav aria-label="breadcrumb">
									<ol class="breadcrumb mb-0 p-0">
										<li class="breadcrumb-item"><router-link to="/"><i class="bx bx-home-alt"></i> Home</router-link>
										</li>
										<li class="breadcrumb-item active" aria-current="page">My Orders</li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
				</section>
                <!--end breadcrumb-->
				<section class="py-4">
					<div class="container">
						<h3 class="d-none">Account</h3>
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-4">
										<UserSidebar />
									</div>
									<div class="col-lg-8">
										<div class="card shadow-none mb-0">
											<div class="card-body">
												<form @submit.prevent="doChangePassword" class="row g-3">
													<div class="col-12">
														<label class="form-label">Username</label>
														<input v-model="form.username" type="text" class="form-control" disabled>
													</div>
													<div class="col-12">
														<label class="form-label">Email address</label>
														<input v-model="form.email" type="email" class="form-control" disabled>
													</div>
													<div class="col-12">
														<label class="form-label">Current Password</label>
														<input v-model="form.currentPassword" type="password" min="6" required class="form-control">
													</div>
													<div class="col-12">
														<label class="form-label">New Password</label>
														<input v-model="form.newPassword" type="password" min="6" required class="form-control">
													</div>
													<div class="col-12">
														<label class="form-label">Confirm New Password</label>
														<input v-model="form.confirmPassword" type="password" min="6" required class="form-control">
													</div>
													<div class="col-12">
														<button type="submit" class="btn btn-light btn-ecomm">Save Changes</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								<!--end row-->
							</div>
						</div>
					</div>
				</section>
        </div>
    </div>
</template>
<script>
import UserSidebar from "../components/UserSidebar.vue";
export default {
    components: {
        UserSidebar: UserSidebar,
    },
    data() {
        return {
            form: {
                username: "",
                email: "",
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
            },

        }
    },
    mounted() {
        this.getUser();
    },
    methods: {
        getUser(){
            axios.get('/api/user-info',{
                headers: {
                    Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
                }
            })
            .then((response) => {
                this.form.username = response.data.username;
                this.form.email = response.data.email;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        doChangePassword(){

            // validate for change password
            if(!this.form.currentPassword || !this.form.newPassword || !this.form.confirmPassword){
                this.$swal.fire({
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    toast: true,
                    position: "top-end",
                    background: "rgb(11 70 49)",
                    icon: "error",

                    title: '<span style="color:#fff">Please fill out<span>',
                });
                return;
            }
            if(this.form.newPassword != this.form.confirmPassword){
                this.$swal.fire({
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    toast: true,
                    position: "top-end",
                    background: "rgb(11 70 49)",
                    icon: "error",

                    title: '<span style="color:#fff">Password and confirmation password do not match.<span>',
                });
                return;
            }
            if(this.form.newPassword.length < 6){
                this.$swal.fire({
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    toast: true,
                    position: "top-end",
                    background: "rgb(11 70 49)",
                    icon: "error",

                    title: '<span style="color:#fff">Password must be at least 6 characters<span>',
                });
                return;
            }

            var pace = document.getElementsByClassName("pace-inactive");
            pace[0].className = "pace pace-active";

            let fd = new FormData();

            fd.append("current_password", this.form.currentPassword);
            fd.append("new_password", this.form.newPassword);
            fd.append("confirm_password", this.form.confirmPassword);

            axios.get("/sanctum/csrf-cookie").then((response) => {
                axios.post("/api/change-password", fd, {
                    headers: {
                        Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
                    }
                }).then((response2)=>{
                    if(response2?.data?.status==1){
                        this.form.currentPassword = "";
                        this.form.newPassword = "";
                        this.form.confirmPassword = "";
                        this.$swal.fire({
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            toast: true,
                            position: "top-end",
                            background: "rgb(11 70 49)",
                            icon: "success",

                            title: '<span style="color:#fff">'+response2.data.message+'<span>',
                        });
                    }else if(response2?.data?.message){
                        this.$swal.fire({
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            toast: true,
                            position: "top-end",
                            background: "rgb(11 70 49)",
                            icon: "error",

                            title: '<span style="color:#fff">'+response2.data.message+'<span>',
                        });
                    }
                });

            });
            var pace2 = document.getElementsByClassName("pace-active");
            pace2[0].className = "pace pace-inactive";
        },
        logout() {
            // sessionStorage.removeItem("username");
            // sessionStorage.removeItem("myOrder");
            // sessionStorage.removeItem("cart");
            // sessionStorage.removeItem("btnupdateOrder");
            // sessionStorage.removeItem("access_token");
            // this.btnupdateOrder = false;
            // this.loginame = null;
            // this.username = "";
            // this.$parent.phone = "";
            // this.editQty = null;
            // this.cart = null;
            // this.$parent.$parent.cart = {
            //     totalprice: 0,
            //     totalqty: 0,
            //     item: [],
            // };
            // // window.location.replace(window.location.href);
            // window.location.href="/";
        },
    }
};
</script>

<style scoped>
</style>
