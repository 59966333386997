<template>
    <div>
        <section class="py-3 border-bottom d-none d-md-flex">
            <div class="container">
                <div class="page-breadcrumb d-flex align-items-center">
                    <h3 class="breadcrumb-title pe-3">{{ $route.query.name }}</h3>
                    <div class="ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb mb-0 p-0">
                                <li class="breadcrumb-item">
                                    <router-link to="/"> <i class="bx bx-home-alt"></i> Home</router-link>
                                </li>
                                <li class="breadcrumb-item"><a href="javascript:;">{{ $route.query.name }}</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-4">
            <div class="container">
                <div class="product-grid">
                    <div v-if="products" class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                        <div v-for="(item, idx) in products" :key="idx">
                            <ProductThumb :storeName="$parent.$parent.storeName" :item="item" :key="idx" width="100%" />
                        </div>
                    </div>
                    <!--end row-->
                </div>
            </div>
        </section>
        <ModalQuickViewProduct v-if="modalProduct" @close="closeModal()" :product="modalProduct" />
    </div>
</template>
<script>
import ProductThumb from "../ProductThumb.vue";
import ModalQuickViewProduct from "../ModalQuickViewProduct.vue";
export default {
    components: {
        ProductThumb,
        ModalQuickViewProduct
    },
    data() {
        return {
            products: null,
            categoryName: null,
            modal: false,
            modalProduct: null,
        };
    },
    mounted() {
        this.getProductTopSale();
    },
    watch:{
        $route (to, from){
            this.getProductTopSale();
        },

    },
    methods: {
        getProductTopSale() {
            axios.get("/api/get_product_by_category?store="+this.$parent.$parent.storeName+"&name="+this.$route.query.name+"&search="+this.$route.query.search).then((response) => {
                this.products = response.data.result;
                if(this.$route.query.search){
                    let toSearch = this.$route.query.search;
                    var results = [];
                    const multiSearchAnd = (text, searchWords) => (
                        searchWords.every((el) => {
                            return text.match(new RegExp(el,"i"))
                        })
                    )
                    for(var i=0; i<this.products.length; i++) {
                        if(multiSearchAnd(this.products[i]['name'], toSearch.split(" "))) {
                            results.push(this.products[i]);
                        }
                    }
                    this.products = results;
                }
            });
        },
        showModal(value){
            if(value){
                this.modalProduct = value;
            }
        },
        closeModal(){
            this.modalProduct = null;
            document.querySelector(".modal-backdrop").remove();
            document.querySelector("body").classList.remove("modal-open");
        }
    },
};
</script>
<style scoped>

</style>
