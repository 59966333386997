<template>
    <div class="col">
        <div class="card rounded-0 product-card">
            <router-link :to="`/productDetail/${item.id}`">
                <img :src="item.picture" class="card-img-top" alt="...">
            </router-link>
            <div class="card-body">
                <div class="product-info">
                    <a href="javascript:;">
                        <p v-if="item.sold>0" class="product-catergory font-13 mb-1">{{item.sold}} {{item.sold>1?' Orders':' Order'}}</p>
                    </a>
                    <a href="javascript:;">
                        <h6 class="product-name mb-2">{{ item.name }}</h6>
                    </a>
                    <div class="d-flex align-items-center">
                        <div v-if="item.regularPrice > 0 && item.savePrice" class="mb-1 product-price">
                            <span class="me-1 text-decoration-line-through">${{ item.regularPrice.toFixed(2) }}</span>
                            <span class="text-white fs-5">${{ item.price.toFixed(2) }}</span>

                        </div>
                        <div v-else class="mb-1 product-price">
                            <!-- <span class="me-1 text-decoration-line-through">{{ item.regularPrice }}</span> -->
                            <span class="text-white fs-5">${{ item.price.toFixed(2) }}</span>
                        </div>
                        <div class="cursor-pointer ms-auto">

                            <span v-if="item.savePrice" class="discount"> {{item.savePrice}} </span>
                        </div>
                    </div>
                    <div class="product-action mt-2">
                        <div class="d-grid gap-2">
                            <a href="javascript:;" class="btn btn-light btn-ecomm" @click="AddToCart(item)">	<i class='bx bxs-cart-add'></i>Add to Cart</a>
                            <a href="javascript:;" class="btn btn-link btn-ecomm" data-toggle="modal" data-target="#QuickViewProduct" @click="$parent.showModal(item)"><i class='bx bx-zoom-in'></i>Quick View</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        width: {
            type: String,
            default: "",
        },
        storeName: {
            type: String,
            default: "",
        },
    },
    mounted(){

    },
    methods: {
        AddToCart(item) {
            const dataItem = { ...item, ...{ qty: 1,branchName: this.storeName } };
            console.log(this.$parent);
            this.$parent.$parent.$parent.itemAdd = dataItem;
            this.$swal.fire({
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                toast: true,
                position: "top-end",
                background: "rgb(11 70 49)",
                icon: "success",

                title: '<span style="color:#fff">Item Added!<span>',
            });
            document.querySelector(".swal2-top-end").style.zIndex = 999999999;
        },
        showModal(item) {
            this.$parent.showModal(item ? item : null);
            // console.log(this.$parent);
        },
    },
};
</script>

<style scoped>
.product-block h4 {
  min-height: 64px;
}
.item-sold{
    font-weight: 600;
    padding-left: 15px;
    font-size: 16px;
}
.discount{
    background: #f00;
    color: #fff;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 16px;
    border-radius: 0 0 10px 0;
}
</style>
