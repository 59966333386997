<template>
    <div class="header-wrapper bg-dark-1">

    </div>
</template>
<script>
  export default {
    components: {

    },
    data() {
      return {

      };
    },
    mounted() {

    },
    methods: {

    },
    watch: {

    },
  };
</script>
<style scoped>

</style>
