<template>
  <div class="page-wrapper">
        <div class="page-content">
             <Product />
             <SupportInfo />
             <Brands />

        </div>
    </div>
</template>
<script>
import Product from "../components/category/Product.vue";
import SupportInfo from "../components/home/SupportInfo.vue";
import Brands from "../components/home/Brands.vue";

export default {
  components: {
    Product,
    SupportInfo,
    Brands,
  },
};
</script>

<style scoped>
</style>
