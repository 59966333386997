<template>
    <div id="app">
      <Header v-if="branchInfo" :info="branchInfo" />
      <!-- <MainHeader v-else /> -->
      <router-view v-if="items"></router-view>
      <Footer v-if="branchInfo" :info="branchInfo" />
      <!-- <MainFooter v-else /> -->
    </div>
  </template>
  <script>
  import Header from "./components/Header";
  import Footer from "./components/Footer";
  import MainHeader from "./components/MainHeader.vue";
  import MainFooter from "./components/MainFooter.vue";
//   import Skeleton from "./components/PageWrapper.vue";

  export default {
    components: {
      MainHeader,
      MainFooter,
      Header,
      Footer,
    //   Skeleton,
    },
    name: "App",
    data() {
      return {
        branchInfo: null,
        search: null,
        items: null,
        itemAdd: null,
        cart: {
          totalprice: 0,
          totalqty: 0,
          item: [],
        },
        saleOrder: null,
        removeCart: null,
        currentTabComponent: null,
        storeName: 'Demo'
      };
    },
    mounted() {
        let storeId = "Demo";
      if (sessionStorage.getItem("cart")) {
        this.cart = JSON.parse(sessionStorage.getItem("cart"));

      }
      if (storeId) {
        axios
          .get("/api/get_branch/" + storeId)
          .then((response) => {
            this.branchInfo = response.data;
          });
        axios
          .get("/api/get_item/" + storeId)
          .then((response) => {
            this.items = response.data.result;
          });
      }
    },

    watch: {
        cart(value){
            this.cart = value;
        },
      itemAdd(value) {
        if (value) {
          var idadd = value.id;

          if (JSON.parse(sessionStorage.getItem("btnupdateOrder"))) {
            var ct = false;

            this.cart.item.forEach((element) => {
              if (element.itemId == idadd && value.id != null) {
                value.id = element.id;
                ct = true;
              }
              if (ct == false && element.id != null) {
                value.id = null;
                value.itemId = idadd;
                value.saleOrderId = this.$children[0].idOrder;
              }
            });
          }
          this.itemAdd = value;
          var j = 0;
          if (this.cart.item.length > 0) {
            for (let i = 0; i < this.cart.item.length; i++) {
              if (this.itemAdd.name == this.cart.item[i].name) {
                this.cart.item[i].qty = this.cart.item[i].qty + value.qty;
                this.cart.item[i].quantity = this.cart.item[i].qty;
                this.cart.item[i].amount = this.cart.item[i].qty * this.cart.item[i].price;
                j++;
              }
            }
            if (j == 0) {
              const newItem = {
                ...this.itemAdd,
                ...{
                  qty: value.qty,
                  quantity: value.qty,
                  amount: this.itemAdd.price * value.qty,
                },
              };
              this.cart.item = [...this.cart.item, ...[newItem]];
            }
          } else {
            const newItem = {
              ...this.itemAdd,
              ...{
                qty: value.qty,
                quantity: value.qty,
                amount: this.itemAdd.price * value.qty,
              },
            };
            this.cart.item = [...this.cart.item, ...[newItem]];
          }

          this.itemAdd = null;
          this.$children[2].itemAdd = null;
          var totalPrice = 0;
          var totalQty = 0;
          this.cart.item.forEach((element) => {
            totalPrice += element.amount;
            totalQty += element.qty;
          });
          this.cart.totalqty = totalQty;
          this.cart.totalprice = totalPrice;
          sessionStorage.setItem("cart", JSON.stringify(this.cart));
        }
        this.$children[0].cart = this.cart;
      },
      removeCart(value) {
        for (var j = 0; j < this.cart.item.length; j++) {
          if (value == this.cart.item[j].id) {
            this.cart.item.splice(j, 1);
          }
        }

        var totalPrice = 0;
        var totalQty = 0;
        this.cart.item.forEach((element) => {
          totalPrice += element.amount;
          totalQty += element.qty;
        });
        this.cart.totalqty = totalQty;
        this.cart.totalprice = totalPrice;
        sessionStorage.setItem("cart", JSON.stringify(this.cart));
      },
      currentTabComponent(value) {
        this.$children[2].currentTabComponent = value;
      },
      branchInfo(value) {
        this.branchInfo = value;
      },
      search(value) {
        if (value && this.$children[2].currentTabComponent == "DetailItem") {
          this.$children[2].oldTabComponent = "DetailItem";
          this.$children[2].currentTabComponent = "ListItem";
        } else if (!value && this.$children[2].oldTabComponent == "DetailItem") {
          this.$children[2].currentTabComponent = "DetailItem";
        }

        this.$children[2].search = value;
      },
    },
  };
  </script>
  <style scoped>

  </style>
