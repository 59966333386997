<template>
    <footer>
        <section class="py-4 bg-dark-1">
            <div class="container">
                <div class="row row-cols-1 row-cols-lg-3 row-cols-xl-4">
                    <div class="col">
                        <div class="footer-section1 mb-3">
                            <h6 class="mb-3 text-uppercase">Address</h6>
                            <div class="address mb-3">
                                <!-- <p class="mb-0 text-uppercase text-white">Address</p> -->
                                <!-- get address from contact -->
                                <p class="mb-0 font-12">{{ getPreference('Address') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="footer-section1 mb-3">
                            <h6 class="mb-3 text-uppercase">Contact</h6>
                            <div class="address mb-3">
                                <!-- <p class="mb-0 text-uppercase text-white">Address</p> -->
                                <p class="mb-0 font-13">Email: {{ getPreference('Email') }}</p>
                                <p class="mb-0 font-13">Mobile : {{ getPreference('Phone') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="footer-section1 mb-3">
                            <h6 class="mb-3 text-uppercase">WORKING</h6>
                            <div class="address mb-3">
                                <p class="mb-0 text-uppercase text-white">WORKING DAYS</p>
                                <p class="mb-0 font-13">{{ getPreference('Work Day') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="footer-section2 mb-3">
                            <h6 class="mb-3 text-uppercase">Categories</h6>
                            <ul class="list-unstyled">
                                <li class="mb-1"><a href="/category?name=All"><i class='bx bx-chevron-right'></i> All</a>
                                </li>
                                <li class="mb-1"><a href="/category?name=Supplement"><i class='bx bx-chevron-right'></i> Supplement</a>
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>
                <!--end row-->
                <hr/>
                <div class="row align-items-center">
                    <div class="col">
                        <p class="mb-0 text-center">Copyright © 2023. All right reserved.</p>
                    </div>

                </div>
                <!--end row-->
            </div>
        </section>
    </footer>
</template>
<script>
  export default {
    props: {
        info: {
            type: Object,
            default: null
        }
    },
    components: {

    },
    data() {
      return {

      };
    },
    mounted() {

    },
    methods: {
        getPreference(key){
            let contact = this.info.contact;
            let value = '';
            // value = contact.filter(x => x.key == key)[0]['value']
            contact.forEach(element => {
                if(element.key == key){
                    value = element.value;
                }
            });
            return value;
        }
    },
    watch: {

    },
  };
</script>
<style scoped>

</style>
