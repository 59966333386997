<template>
<!--start page wrapper -->
<div class="page-wrapper">
    <div class="page-content">
        <!--start breadcrumb-->
        <section class="py-3 border-bottom d-none d-md-flex">
            <div class="container">
                <div class="page-breadcrumb d-flex align-items-center">
                    <h3 class="breadcrumb-title pe-3">Forgot Password</h3>
                    <div class="ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb mb-0 p-0">
                                <li class="breadcrumb-item"><router-link to="/"><i class="bx bx-home-alt"></i> Home</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Forgot Password</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <!--end breadcrumb-->
        <!--start shop cart-->
        <section class="">
            <div class="container">
                <div class="d-flex justify-content-center my-lg-4">
                    <div class="card forgot-box">
                        <div class="card-body">
                            <div class="p-4 rounded  border">
                                <div class="text-center">
                                    <h4 class="font-weight-bold">Forgot Password?</h4>
                                    <p class="">Enter your registered email ID to reset the password</p>
                                </div>

                                <div class="login-separater text-center mb-4 mt-4">
                                    <hr/>
                                </div>
                                <div class="my-4">
                                    <label class="form-label">Email id</label>
                                    <input  v-model="email" type="email" class="form-control" placeholder="example@user.com" />
                                </div>
                                <div class="d-grid gap-2">
                                    <button @click="doSend()" type="button" class="btn btn-light">Send</button>
                                    <router-link to="/signin" class="btn btn-light"><i class='bx bx-arrow-back me-1'></i>Back to Login</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--end shop cart-->
    </div>
</div>
<!--end page wrapper -->
    </template>
    <script>

    export default {
        components: {

        },
        data() {
            return {
                email: null,
            };
        },
        mounted() {

        },
        methods: {
            doSend() {
                if(this.email){

                    var pace = document.getElementsByClassName("pace-inactive");
                    pace[0].className = "pace pace-active";

                    let fd = new FormData();
                    fd.append("email", this.email);
                    axios.post("/api/forgot-password", fd).then((response)=>{
                        if(response.data.status == 1){
                            this.$swal.fire({
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                                toast: true,
                                position: "top-end",
                                background: "rgb(11 70 49)",
                                icon: "success",

                                title: '<span style="color:#fff">'+response.data.message+'<span>',
                            });
                            this.email = "";
                        }else{
                            this.$swal.fire({
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                                toast: true,
                                position: "top-end",
                                background: "rgb(11 70 49)",
                                icon: "error",

                                title: '<span style="color:#fff">'+response.data.message+'<span>',
                            });
                        }
                    }).catch((error)=>{

                    });

                    var pace2 = document.getElementsByClassName("pace-active");
                    pace2[0].className = "pace pace-inactive";
                }

            }
        },
    };
    </script>

    <style scoped>
    </style>
