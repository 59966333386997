<template>
<!--start page wrapper -->
<div v-if="blog" class="page-wrapper">
    <div class="page-content">
        <!--start breadcrumb-->
        <section class="py-3 border-bottom d-none d-md-flex">
            <div class="container">
                <div class="page-breadcrumb d-flex align-items-center">
                    <h3 class="breadcrumb-title pe-3">{{ blog.title }}</h3>
                    <div class="ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb mb-0 p-0">
                                <li class="breadcrumb-item">
                                    <router-link to="/">
                                        <i class="bx bx-home-alt"></i> Home
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/blog">Blog</router-link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <!--end breadcrumb-->
        <!--start page content-->
        <section class="py-4">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-9">
                        <div class="blog-right-sidebar p-3">
                            <div class="card shadow-none bg-transparent">
                                <img :src="blog.thumbnail" class="card-img-top" alt="">
                                <div class="card-body p-0 cus-blog-detail">
                                    <div class="list-inline mt-4">
                                        <a href="javascript:;" class="list-inline-item"><i class='bx bx-calendar me-1'></i>{{ getDate(blog.created_at) }}</a>
                                    </div>
                                    <h4 class="mt-4">{{ blog.title }}</h4>
                                    <div v-html="blog.content"></div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <div class="blog-left-sidebar p-3">
                            <form>
                                <div class="blog-categories mb-3">
                                    <h5 class="mb-4">Blog Categories</h5>
                                    <div class="list-group list-group-flush">
                                        <router-link v-if="blogCategory.length" to="/blog" class="list-group-item bg-transparent">
                                            <i class='bx bx-chevron-right me-1'></i> All
                                        </router-link>
                                        <router-link v-for="(item, idx) in blogCategory" :key="idx" :to="'/blog?category='+item.id" class="list-group-item bg-transparent">
                                            <i class='bx bx-chevron-right me-1'></i> {{ item.category }}
                                        </router-link>
                                    </div>
                                </div>
                                <div class="blog-categories mb-3">
                                    <h5 class="mb-4">Recent Posts</h5>
                                    <div v-for="(item, idx) in blogListRecent" :key="idx">
                                        <div class="d-flex align-items-center">
                                            <img :src="item.thumbnail" width="75" alt="">
                                            <div class="ms-3"> <router-link :to="'/blog/'+item.id" class="fs-6">{{ item.title }}</router-link>
                                                <p class="mb-0">{{ getDate(item.created_at) }}</p>
                                            </div>
                                        </div>
                                        <div v-if="(idx + 1) < blogListRecent.length" class="my-3 border-bottom"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!--end row-->
            </div>
        </section>
        <!--end start page content-->
    </div>
</div>
<!--end page wrapper -->
</template>
  <script>

  export default {
    components: {

    },
    data() {
      return {
        blog: null,
        blogListRecent: [],
        blogCategory: [],
      };
    },
    mounted() {
        this.getBlog();
        this.getBlogListRecent();
        this.getBlogCategory();
    },
    methods: {
        getBlog(){
            let id = this.$route.params.id;
            axios.get('/api/blog-detail/'+id)
                .then((response) => {
                    this.blog = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getBlogListRecent(){
            axios.get('/api/blog/recent')
                .then((response) => {
                    this.blogListRecent = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getBlogCategory() {
            axios.get('/api/blog/category')
                .then((response) => {
                    this.blogCategory = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getDate(date) {
            var toDate = new Date(date).toISOString().slice(0, 10);
            return toDate;
        },
    },
    watch: {
        $route (to, from){
            this.getBlog();
        }
    },
  };
  </script>
  <style>
    .cus-blog-detail{
        overflow: hidden;
    }
  </style>
