<template>
<div class="card shadow-none mb-3 mb-lg-0">
    <div class="card-body">
        <div class="list-group list-group-flush">
            <router-link to="/order" :class="$route.path == '/order'?'':'bg-transparent'" class="list-group-item d-flex justify-content-between align-items-center">
                Orders <i class='bx bx-cart-alt fs-5'></i>
            </router-link>
            <router-link to="/account" :class="$route.path == '/account'?'':'bg-transparent'" class="list-group-item d-flex justify-content-between align-items-center">
                Account <i class="bx bx-user-circle fs-5"></i>
            </router-link>
            <a href="javascript:void(0)" @click="logout()" class="list-group-item d-flex justify-content-between align-items-center bg-transparent">Logout <i class='bx bx-log-out fs-5'></i></a>
        </div>
    </div>
</div>
</template>
<script>
  export default {
    props: {
        info: {
            type: Object,
            default: null
        }
    },
    components: {

    },
    data() {
      return {

      };
    },
    mounted() {

    },
    methods: {
        logout() {
            sessionStorage.removeItem("username");
            sessionStorage.removeItem("myOrder");
            sessionStorage.removeItem("cart");
            sessionStorage.removeItem("btnupdateOrder");
            sessionStorage.removeItem("access_token");
            // window.location.replace(window.location.href);
            window.location.href="/";
        },
    },
    watch: {

    },
  };
</script>
<style scoped>

</style>
