<template>
  <div class="page-wrapper">
			<div class="page-content">
				<!--start breadcrumb-->
				<section class="py-3 border-bottom d-none d-md-flex">
					<div class="container">
						<div class="page-breadcrumb d-flex align-items-center">
							<h3 class="breadcrumb-title pe-3">My Orders</h3>
							<div class="ms-auto">
								<nav aria-label="breadcrumb">
									<ol class="breadcrumb mb-0 p-0">
										<li class="breadcrumb-item"><router-link to="/"><i class="bx bx-home-alt"></i> Home</router-link>
										</li>
										<li class="breadcrumb-item active" aria-current="page">My Orders</li>
									</ol>
								</nav>
							</div>
						</div>
					</div>
				</section>
                <!--end breadcrumb-->
				<section class="py-4">
					<div class="container">
						<h3 class="d-none">Account</h3>
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-4">
                                        <UserSidebar />
									</div>
									<div class="col-lg-8">
										<div class="card shadow-none mb-0">
											<div class="card-body">
												<div class="table-responsive">
													<table class="table">
														<thead class="table-light">
															<tr>
																<th>Order</th>
																<th>Date</th>
																<th>Status</th>
																<th>Total</th>
																<th>Actions</th>
															</tr>
														</thead>
														<tbody>
                                                            <tr v-for="(item, idx) in dataMyOrder" :key="idx">
                                                                <td>{{ item.reference }}</td>
                                                                <th scope="row">{{ item.date.slice(0, 10) }}</th>

                                                                <td><div class="badge rounded-pill bg-light w-100">{{ item.status }}</div></td>
                                                                <td>${{ item.amount }}</td>
                                                                <td v-if="item.status == 'New'">

                                                                    <div class="d-flex gap-2">
                                                                        <a
                                                                            title="Edit"
                                                                            href="javascript:;"
                                                                            data-dismiss="modal"
                                                                            @click="tbn_editOrder(idx)"
                                                                            class="btn btn-light btn-sm rounded-0"
                                                                            >
                                                                            Edit
                                                                            </a>
                                                                            <a
                                                                            title="Delete"
                                                                            href="javascript:;"
                                                                            @click="tbn_deleteCart(item.id)"
                                                                            class="btn btn-light btn-sm rounded-0"
                                                                            >
                                                                            Delete
                                                                            </a>
																	</div>
                                                                </td>
                                                                <td v-else>
                                                                    <a
                                                                    title="view"
                                                                    href="javascript:;"
                                                                    @click="tbn_view_order(item.id)"
                                                                    class="btn btn-light btn-sm rounded-0"
                                                                    >
                                                                    View
                                                                    </a>
                                                                </td>
                                                                </tr>

														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!--end row-->
							</div>
						</div>
					</div>
				</section>

                <!-- Modal Print Preview -->
                <a
                href="#printpreview"
                data-toggle="modal"
                class="account-link"
                ref="printpreview"
                ></a>
                <div v-if="showModalPrint"
                class="modal fade show"
                id="printpreview"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                >
                <div class="modal-dialog" role="document">
                    <div
                    class="modal-content"
                    style="order-radius: 0; border-radius: 0"
                    >
                    <div class="modal-header" style="border-bottom: none">
                        <button
                            type="button"
                            class="btn-close float-end"
                            data-bs-dismiss="modal"
                            @click="showModalPrint=false"
                        ></button>
                        <span
                        class="bx bx-printer"
                        @click="print_receipt()"
                        style="position: absolute; left: 46%; font-size: 25px; padding: 5px; cursor: pointer;"
                        ></span>
                    </div>
                    <div class="modal-body" v-if="printpreview">

                        <div class="row">
                        <div
                            class="col-md-12 text-center"
                            style="margin-bottom: 21px"
                        >
                            <img
                            :src="this.$parent.branchInfo.picture || 'https://ezbuy.today/images/logo-color.svg'"
                            width="100px"
                            height="50px"
                            alt="Logo"
                            style="margin-bottom: 20px"
                            />
                            <br />
                            <label for="">Name</label> :
                            <span>{{ printpreview.saleOrder.customerName }}</span>
                            <br />
                            <label for="">Date</label> :
                            <span>{{
                            printpreview.saleOrder.date.slice(0, 10)
                            }}</span>
                            <br />
                            <label for="">Reference</label> :
                            <span>{{ printpreview.saleOrder.reference }}</span>
                        </div>
                        </div>
                        <div class="saleOrder">
                        <div class="saleOrder_l">
                            <div class="clear"></div>
                        </div>
                        </div>

                        <table class="table" style="margin-top: 10px">
                        <thead>
                            <tr>
                            <th scope="col">No</th>
                            <th scope="col">Name</th>
                            <th scope="col">Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="(
                                item, idx
                            ) in printpreview.saleOrderTransactions"
                            :key="idx"
                            >
                            <td scope="row">{{ idx + 1 }}</td>
                            <td>{{ item.itemName }}</td>
                            <td>${{ item.price }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>${{ item.amount }}</td>
                            </tr>
                            <tr>
                            <th colspan="4" style="text-align: right">Sutotal</th>
                            <th>
                                ${{ printpreview.saleOrder.grandTotalAmount }}
                            </th>
                            </tr>
                            <tr>
                            <th colspan="4" style="text-align: right">
                                Sale Tax 0%
                            </th>
                            <th>$0.00</th>
                            </tr>
                            <tr>
                            <th colspan="4" style="text-align: right">TOTAL</th>
                            <th>
                                ${{ printpreview.saleOrder.grandTotalAmount }}
                            </th>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
                <!--end model Print Preview -->




        </div>
    </div>
</template>
<script>
import UserSidebar from "../components/UserSidebar.vue";
export default {
  components: {
    UserSidebar: UserSidebar,
  },
    data() {
        return {
            info: null,
            loginame: null,
            dataMyOrder: null,
            BranchId: null,
            showModalPrint: false,
            printpreview: null,
            saleOrderTransactions: null,
            saleOrder: null,
            btnupdateOrder: false,
            address_order: null,
        }
    },
    mounted() {
        this.info = this.$parent.branchInfo;
        this.branchId = this.$parent.storeName;
        if (!sessionStorage.getItem("username")) {
            this.$router.push("/");
        }
        if (sessionStorage.getItem("username")) {
            this.loginame = sessionStorage.getItem("username");
            this.btnMyOrder();
        }
    },
    methods: {
        btnMyOrder() {
            if (this.loginame) {
                axios
                .get(
                    "/api/get_order/" + this.branchId + "/" + this.loginame
                )
                .then((response) => {
                    this.dataMyOrder = response.data.result;
                    sessionStorage.setItem(
                    "myOrder",
                    JSON.stringify(response.data.result)
                    );
                    this.dataMyOrder = JSON.parse(sessionStorage.getItem("myOrder"));
                });
            }
        },
        tbn_editOrder(inx) {
            this.idOrder = this.dataMyOrder[inx].id;
            // check btn update order
            this.btnupdateOrder = true;
            sessionStorage.setItem(
                "btnupdateOrder",
                JSON.stringify(this.btnupdateOrder)
            );
            // clear cart
            this.$parent.cart = {
                totalprice: 0,
                totalqty: 0,
                item: [],
            };
            axios
                .get(
                "/api/get_edit_order/" +
                    this.branchId +
                    "/" +
                    this.idOrder
                )
                .then((response) => {
                this.address_order = response.data.result.saleOrder.shippingAddress;
                sessionStorage.setItem("saleOrder", JSON.stringify(response.data.result.saleOrder));
                this.$parent.cart.item = [
                    ...this.$parent.cart.item,
                    ...response.data.result.saleOrderTransactions,
                ];
                var data = {};
                var items = [];
                for (let i = 0; i < this.$parent.cart.item.length; i++) {
                    data = {
                    ...data,
                    ...this.$parent.cart.item[i],
                    ...{
                        qty: this.$parent.cart.item[i].quantity,
                        name: this.$parent.cart.item[i].itemName,
                    },
                    };
                    items = [...items, ...[data]];
                }
                this.$parent.cart.item = [];
                this.$parent.cart.item = items;

                //  count total qty and total price
                var totalPrice = 0;
                var totalQty = 0;
                this.$parent.cart.item.forEach((element) => {
                    totalPrice += element.amount;
                    totalQty += element.qty;
                });
                this.$parent.cart.totalqty = totalQty;
                this.$parent.cart.totalprice = totalPrice;
                sessionStorage.setItem("cart", JSON.stringify(this.$parent.cart));
                this.cart = this.$parent.cart;
                });
            },
        tbn_deleteCart(id) {
            axios
                .delete("/api/get_delete/" + this.branchId + "/" + id)
                .then((response) => {
                    this.dataDelete = response.data.result;
                    this.$swal.fire({
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        toast: true,
                        position: "top-end",
                        background: "rgb(11 70 49)",
                        icon: "success",

                        title: '<span style="color:#fff">Deleted!<span>',
                    });
                    this.btnMyOrder();
                });
            if (this.dataMyOrder.length == 1) {
                this.cart = null;
                this.$parent.$parent.cart = {
                totalprice: 0,
                totalqty: 0,
                item: [],
                };
                this.btnupdateOrder = false;
                sessionStorage.removeItem("cart");
                sessionStorage.removeItem("btnupdateOrder");
            }
        },
        tbn_view_order(id) {
        // get print Preview
            axios
                .get("/api/get_printpreview/" + this.branchId + "/" + id)
                .then((response) => {
                this.printpreview = response.data.result;
                this.showModalPrint = true;
                // this.$refs.printpreview.click();
                // this.$refs.closemyOrder.click();
            });
        },

    }
};
</script>

<style scoped>
</style>
