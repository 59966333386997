<template>
    <div class="primary-menu border-top">
        <div class="container">
            <nav id="navbar_main" class="mobile-offcanvas navbar navbar-expand-lg">
                <div class="offcanvas-header">
                    <button class="btn-close float-end"></button>
                    <h5 class="py-2 text-white">Navigation</h5>
                </div>
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <router-link class="nav-link" to="/">Home </router-link>
                    </li>
                    <li class="nav-item dropdown">	<a class="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown">Categories <i class='bx bx-chevron-down'></i></a>
                        <div class="dropdown-menu dropdown-large-menu">
                            <div class="row">
                                <div class="col-md-12">
                                    <!-- <h6 class="large-menu-title">Category</h6> -->
                                    <ul v-if="categories" class="">
                                        <li v-for="(item, idx) in categories" :key="idx">
                                            <router-link  :to="'/category?name=' + item.name">
                                                {{ item.name }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <!-- end row -->
                        </div>
                        <!-- dropdown-large.// -->
                    </li>

                    <li class="nav-item">
                        <router-link class="nav-link" to="/blog">Blog </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/about">About Us </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/contact">Contact Us </router-link>
                    </li>

                </ul>
            </nav>
        </div>
    </div>
</template>
<script>
  export default {
    components: {

    },
    data() {
      return {
        categories: null,
      };
    },
    mounted() {
        this.getCategory();
    },
    methods: {
        getCategory() {
            axios.get("/api/get_category/Demo")
        .then((response) => {
          this.categories = response.data.result;
        });
        },
    },
    watch: {

    },
  };
</script>
<style scoped>

</style>
