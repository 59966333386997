<template>
    <!--start page wrapper -->
    <div class="page-wrapper">
        <div class="page-content">
            <!--start breadcrumb-->
            <section class="py-3 border-bottom d-none d-md-flex">
                <div class="container">
                    <div class="page-breadcrumb d-flex align-items-center">
                        <div class="breadcrumb-title pe-3">Blog</div>
                        <div class="ms-auto">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0 p-0">
                                    <li class="breadcrumb-item">
                                        <router-link to="/"><i class="bx bx-home-alt"></i> Home</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:;">Blog</a>
                                    </li>

                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <!--end breadcrumb-->
            <!--start page content-->
            <section class="py-4">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-9">
                            <div class="row blog-right-sidebar p-3">
                                <div v-for="(item, idx) in blogList" :key="idx" class="col-md-4 col-sm-12">
                                    <router-link :to="'/blog/'+item.id" class="cus-blog-feature">
                                        <div  class="card">
                                            <img :src="item.thumbnail" class="card-img-top" alt="">

                                            <div class="card-body">
                                                <a href="javascript:;" class="list-inline-item"><i class='bx bx-calendar me-1'></i>{{ getDate(item.created_at) }}</a>
                                                <h5 class="mt-4 cus-title">
                                                    <router-link :to="'/blog/'+item.id"> {{ item.title }} </router-link>
                                                </h5>

                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                                <hr>
                                <!-- <nav class="d-flex justify-content-between" aria-label="Page navigation">
                                    <ul class="pagination">
                                        <li class="page-item"><a class="page-link" href="javascript:;"><i class="bx bx-chevron-left"></i> Prev</a>
                                        </li>
                                    </ul>
                                    <ul class="pagination">
                                        <li class="page-item active d-none d-sm-block" aria-current="page"><span class="page-link">1<span class="visually-hidden">(current)</span></span>
                                        </li>
                                        <li class="page-item d-none d-sm-block"><a class="page-link" href="javascript:;">2</a>
                                        </li>
                                        <li class="page-item d-none d-sm-block"><a class="page-link" href="javascript:;">3</a>
                                        </li>
                                        <li class="page-item d-none d-sm-block"><a class="page-link" href="javascript:;">4</a>
                                        </li>
                                        <li class="page-item d-none d-sm-block"><a class="page-link" href="javascript:;">5</a>
                                        </li>
                                    </ul>
                                    <ul class="pagination">
                                        <li class="page-item"><a class="page-link" href="javascript:;" aria-label="Next">Next <i class="bx bx-chevron-right"></i></a>
                                        </li>
                                    </ul>
                                </nav> -->
                            </div>
                        </div>
                        <div class="col-12 col-lg-3">
                            <div class="blog-left-sidebar p-3">
                                <form>
                                    <div class="position-relative blog-search mb-3">
                                        <input type="text" @keyup="getSearch()" v-model="search" class="form-control form-control-lg rounded-0 pe-5" placeholder="Serach posts here...">
                                        <div class="position-absolute top-50 end-0 translate-middle"><i class='bx bx-search fs-4 text-white'></i>
                                        </div>
                                    </div>
                                    <div class="blog-categories mb-3">
                                        <h5 class="mb-4">Blog Categories</h5>
                                        <div class="list-group list-group-flush">
                                            <router-link v-if="blogCategory.length" to="/blog" class="list-group-item bg-transparent">
                                                <i class='bx bx-chevron-right me-1'></i> All
                                            </router-link>
                                            <router-link v-for="(item, idx) in blogCategory" :key="idx" :to="'/blog?category='+item.id" class="list-group-item bg-transparent">
                                                <i class='bx bx-chevron-right me-1'></i> {{ item.category }}
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="blog-categories mb-3">
                                        <h5 class="mb-4">Recent Posts</h5>
                                        <div v-for="(item, idx) in blogListRecent" :key="idx">
                                            <div class="d-flex align-items-center">
                                                <img :src="item.thumbnail" width="75" alt="">
                                                <div class="ms-3"> <router-link :to="'/blog/'+item.id" class="fs-6">{{ item.title }}</router-link>
                                                    <p class="mb-0">{{ getDate(item.created_at) }}</p>
                                                </div>
                                            </div>
                                            <div v-if="(idx + 1) < blogListRecent.length" class="my-3 border-bottom"></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!--end row-->
                </div>
            </section>
            <!--end start page content-->
        </div>
    </div>
    <!--end page wrapper -->
</template>
  <script>

  export default {
    components: {

    },
    data() {
      return {
        blogList: [],
        blogListRecent: [],
        blogCategory: [],
        search: '',
      };
    },
    mounted() {
        this.getBlogList();
        this.getBlogListRecent();
        this.getBlogCategory();
    },
    methods: {
        // get blog list 10 data per page
        getBlogList() {
            let url = '/api/blog';
            if(this.$route.query.category){
                url += '?category='+this.$route.query.category;
            }
            axios.get(url)
                .then((response) => {
                    this.blogList = response.data.data;
                    console.log("Blog List: ", this.blogList);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getBlogListRecent(){
            axios.get('/api/blog/recent')
                .then((response) => {
                    this.blogListRecent = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getBlogCategory() {
            axios.get('/api/blog/category')
                .then((response) => {
                    this.blogCategory = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getSearch() {
            let url = '/api/blog?search=' + this.search;
            if(this.$route.query.category){
                url += '&category='+this.$route.query.category;
            }
            axios.get(url)
                .then((response) => {
                    this.blogList = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getDate(date) {
            var toDate = new Date(date).toISOString().slice(0, 10);
            return toDate;
        },
    },
    watch: {
        $route (to, from){
            this.getBlogList();
        }
    },
  };
  </script>
<style scoped>
    .cus-title{
        height: 48;
        overflow: hidden;
    }
    .cus-blog-feature img {
        transition: transform .2s; /* Animation */
    }
    .cus-blog-feature:hover img {
        /* transform: scale(1.1); */
        opacity: .8;
    }
</style>
