<template>
    <section class="py-3 border-top border-bottom">
        <div class="container">
            <div class="row row-cols-1 row-cols-lg-3 row-group align-items-center">
                <div class="col p-3">
                    <div class="d-flex align-items-center">
                        <div class="fs-1 text-white">	<i class='bx bx-taxi'></i>
                        </div>
                        <div class="info-box-content ps-3">
                            <h6 class="mb-0">FREE SHIPPING &amp; RETURN</h6>
                            <p class="mb-0">Free shipping on all orders over $49</p>
                        </div>
                    </div>
                </div>
                <div class="col p-3">
                    <div class="d-flex align-items-center">
                        <div class="fs-1 text-white">	<i class='bx bx-dollar-circle'></i>
                        </div>
                        <div class="info-box-content ps-3">
                            <h6 class="mb-0">MONEY BACK GUARANTEE</h6>
                            <p class="mb-0">100% money back guarantee</p>
                        </div>
                    </div>
                </div>
                <div class="col p-3">
                    <div class="d-flex align-items-center">
                        <div class="fs-1 text-white">	<i class='bx bx-support'></i>
                        </div>
                        <div class="info-box-content ps-3">
                            <h6 class="mb-0">ONLINE SUPPORT 24/7</h6>
                            <p class="mb-0">Awesome Support for 24/7 Days</p>
                        </div>
                    </div>
                </div>
            </div>
            <!--end row-->
        </div>
    </section>
</template>
<script>
  export default {
    components: {

    },
    data() {
      return {

      };
    },
    mounted() {

    },
    methods: {

    },
    watch: {

    },
  };
</script>
<style scoped>

</style>
