<template>
    <div class="modal fade" id="QuickViewProduct" data-keyboard="false" data-backdrop="static">
      <div
        class="
          modal-dialog modal-dialog-centered modal-xl modal-fullscreen-xl-down
        "
      >
        <div class="modal-content bg-dark-4 rounded-0 border-0">
          <div v-if="data" class="modal-body">
            <button
              type="button"
              class="btn-close float-end"
              data-bs-dismiss="modal"
              @click="close()"
            ></button>
            <div class="row g-0">
                <div class="col-12 col-lg-5">
                    <div class="image-zoom-section">
                        <div class="product-gallery owl-carousel owl-theme border mb-3 p-3" style="display:block;">

                            <img style="max-width: 100%" v-bind:src="data.picture ||
                                'https://acc.ksoft.solutions/Common/Images/default-item-picture.png'
                                "
                                ref="images"
                            />
                        </div>
                        <div class="product-featured-info">
                            <carousel class="bx-pager">
                            <a
                                v-for="(item, idx) in data.itemPictures"
                                :key="idx"
                                :data-slide-index="idx"
                                href="javascript:;"
                                ><slide
                                ><img
                                    :src="item.picture"
                                    @click="setNewImage" /></slide
                            ></a>
                            </carousel>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7">
                    <div class="product-info-section p-3">
                        <h3 class="mt-3 mt-lg-0 mb-0">{{ data.name }}</h3>
                        <div
                            v-if="data.price && data.regularPrice"
                            class="d-flex align-items-center mt-3 gap-2"
                        >
                            <h5 class="mb-0 text-decoration-line-through text-light-3">
                            ${{ data.regularPrice.toFixed(2) }}
                            </h5>
                            <h4 class="mb-0">${{ data.price.toFixed(2) }}</h4>
                        </div>
                        <div v-else class="d-flex align-items-center mt-3 gap-2">
                            <h4 class="mb-0">${{ data.regularPrice.toFixed(2) }}</h4>
                        </div>
                        <div class="mt-3">
                            <h6>Discription :</h6>
                            <div v-html="data.description"></div>
                        </div>
                        <!-- <dl class="row mt-3">	<dt class="col-sm-3">Product id</dt>
                            <dd class="col-sm-9">#BHU5879</dd>	<dt class="col-sm-3">Delivery</dt>
                            <dd class="col-sm-9">Russia, USA, and Europe</dd>
                        </dl> -->
                        <div class="row row-cols-auto align-items-center mt-3">
                            <div class="col" style="">
                                <label class="form-label">Quantity</label>
                                <!-- <select v-model="CartQty" class="form-select form-select-sm">
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                </select> -->
                                <br />
                                <input v-model="CartQty" min="1" style="font-size:18px; width: 88px; height: 31px;" type="number" />
                            </div>
                            <div class="col" style="">
                                <a href="javascript:;" @click="AddToCart(data)" class="btn btn-white btn-ecomm" style="margin-top: 28px; padding: 5px; border-radius: 2px;"><i class="bx bxs-cart-add"></i>Add to Cart</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <!--end row-->
        </div>
      </div>
    </div>
</template>

<script>
  import { Carousel, Slide } from "vue-carousel";
  export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    components: {
      Carousel,
      Slide,
    },
    data() {
      return {
        data: null,
        CartQty: 1,
      };
    },
    mounted() {
    //   var dateFilter = this.$parent.items.filter(
    //     (elment) => elment.id == this.$route.params.id
    //   );
    //   this.data = dateFilter[0];
        this.data = this.product;
        this.display = this.items;
        setTimeout(() => {
            // this.$refs.images.style.width = "100%";
        }, 100);
    },
    methods: {
        close(){
            this.data = null;
            this.$emit('close');
        },
      setNewImage($event) {
        var src = $event.target.src;
        this.$refs.images.src = src;
      },
      AddToCart(item) {
        if(this.CartQty <=0){
            this.CartQty = 1;
        }
        const dataItem = { ...item, ...{ qty: this.CartQty,branchName:this.$parent.$parent.$parent.storeName } };
        this.$parent.$parent.$parent.itemAdd = dataItem;
        this.$swal.fire({
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          toast: true,
          position: "top-end",
          background: "rgb(11 70 49)",
          icon: "success",

          title: '<span style="color:#fff">Item Added!<span>',
        });
        document.querySelector(".swal2-backdrop-show").style.zIndex = 99999999;
      },
      AddQtyCart(number) {
        this.CartQty += number;
        if (this.CartQty <= 0) {
          this.CartQty = 1;
        }
      },
    },
    watch: {
        CartQty(value) {
            // if (value <= 0) {
            //     value = 1;
            // }
            this.CartQty = parseInt(value);
        },
    },
  };
  </script>
  <style>
  .product-featured-info .bx-pager a {
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px;
  }
  .product-featured-info .bx-pager {
    margin-top: 20px;
  }
  .product-featured-info .bx-pager img {
    width: 100%;
  }
  .cm_img {
    display: flex;
    align-items: center;
  }
  @media (max-width: 414px) {
    .cm_img {
      display: block;
    }
  }
  </style>
