<template>
  <div class="page-wrapper">
        <div class="page-content">
             <Slide />
             <!-- <Information /> -->
             <FeaturedProduct />
             <!-- <Categories /> -->
             <SupportInfo />
             <Brands />

        </div>
    </div>
</template>
<script>
import Slide from "../components/home/SliderSection.vue";
import Information from "../components/home/Information.vue";
import FeaturedProduct from "../components/home/FeaturedProduct.vue";
import Categories from "../components/home/Categories.vue";
import SupportInfo from "../components/home/SupportInfo.vue";
import Brands from "../components/home/Brands.vue";

export default {
  components: {
    Slide,
    Information,
    FeaturedProduct,
    Categories,
    SupportInfo,
    Brands,
  },
};
</script>

<style scoped>
</style>
