<template>
    <section class="slider-section container">
        <div v-if="products" class="first-slider">
            <!-- <div id="carouselExampleDark" class="carousel slide" data-bs-ride="carousel">
                <ol v-if="products" class="carousel-indicators">
                    <li v-for="(item, idx) in products" :key=idx data-bs-target="#carouselExampleDark" :data-bs-slide-to="idx" :class="idx==0?'active':''"></li>
                </ol>
                <div v-if="products" class="carousel-inner">
                    <div v-for="(item, idx) in products" :key=idx class="carousel-item" :class="idx==0?'active':''">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-6 d-none d-lg-flex justify-content-center">
                                <div class="">
                                    <h1 class="h1">{{ item.title }}</h1>
                                    <p class="pb-3" v-html="item.content"></p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <img :src="item.image" class="img-fluid" alt="...">
                            </div>
                        </div>
                    </div>

                </div>
                <a class="carousel-control-prev" href="#carouselExampleDark" role="button" data-bs-slide="prev">	<span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </a>
                <a class="carousel-control-next" ref="next" href="#carouselExampleDark" role="button" data-bs-slide="next">	<span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </a>
            </div> -->
            <carousel :perPage="1" :autoplay="true" :loop="true" :autoplayTimeout="4000">
                <slide v-for="(item, idx) in products" :key="idx">
                    <div class="carousel-item active">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-6 d-none d-lg-flex justify-content-center">
                                <div class="">
                                    <h1 class="h1">{{ item.title }}</h1>
                                    <p class="pb-3" v-html="item.content"></p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <img :src="item.image" class="img-fluid" alt="...">
                            </div>
                        </div>
                    </div>

                </slide>
            </carousel>
        </div>
    </section>
</template>
<script>
  export default {
  components: {

  },
  data() {
    return {
      products: null,
    };
  },
  mounted() {
    this.products = this.$parent.$parent.branchInfo.slide;
  },
  methods: {
    // getProductTopSale() {
    //   axios.get("/api/get_home_product?store="+this.$parent.$parent.storeName).then((response) => {
    //     this.products = response.data.result.slice(0, 3);
    //   });
    // },
  },
};
</script>
<style scoped>

</style>
