<template>
<div class="top-menu border-bottom">
    <div class="container">
        <nav class="navbar navbar-expand">
            <div class="shiping-title text-uppercase font-13 text-white d-none d-sm-flex">Welcome to our KSOFT Store!</div>
            <ul class="navbar-nav ms-auto d-none d-lg-flex">

                <li class="nav-item">
                    <router-link class="nav-link" to="/about">About</router-link>
                </li>

                <!-- <li class="nav-item"> <a class="nav-link" href="blog.html">Blog</a>
                </li> -->
                <li class="nav-item">
                    <router-link class="nav-link" to="/contact">Contact</router-link>
                </li>
            </ul>

            <ul v-if="branchInfo" class="navbar-nav social-link ms-lg-2 ms-auto">
                <li class="nav-item"> <a class="nav-link" target="_blank" :href="getPreference('FaceBook')"><i class='bx bxl-facebook'></i></a>
                </li>
                <li class="nav-item"> <a class="nav-link" target="_blank" :href="getPreference('Twitter')"><i class='bx bxl-twitter'></i></a>
                </li>
                <li class="nav-item"> <a class="nav-link" target="_blank" :href="getPreference('Youtube')"><i class='bx bxl-youtube'></i></a>
                </li>
            </ul>
        </nav>
    </div>
</div>
</template>
<script>
  export default {
    components: {

    },
    data() {
      return {
        branchInfo: null,
      };
    },
    mounted() {
        this.branchInfo = this.$parent.$parent.branchInfo;
    },
    methods: {
        getPreference(key){
            let contact = this.branchInfo.social;
            let value = '';
            // value = contact.filter(x => x.key == key)[0]['value']
            contact.forEach(element => {
                if(element.key == key){
                    value = element.value;
                }
            });
            return value;
        },
    },
    watch: {

    },
  };
</script>
<style scoped>

</style>
