<template>
    <section class="py-4">
        <div class="container">
            <h3 class="d-none">Brands</h3>
            <div class="brand-grid">
                <div class="brands-shops owl-carousel owl-theme border">
                    <div class="item border-end">
                        <div class="p-4">
                            <a href="javascript:;">
                                <img src="/assets_store_emmeline/assets/images/brands/01.png" class="img-fluid" alt="...">
                            </a>
                        </div>
                    </div>
                    <div class="item border-end">
                        <div class="p-4">
                            <a href="javascript:;">
                                <img src="/assets_store_emmeline/assets/images/brands/02.png" class="img-fluid" alt="...">
                            </a>
                        </div>
                    </div>
                    <div class="item border-end">
                        <div class="p-4">
                            <a href="javascript:;">
                                <img src="/assets_store_emmeline/assets/images/brands/03.png" class="img-fluid" alt="...">
                            </a>
                        </div>
                    </div>
                    <div class="item border-end">
                        <div class="p-4">
                            <a href="javascript:;">
                                <img src="/assets_store_emmeline/assets/images/brands/04.png" class="img-fluid" alt="...">
                            </a>
                        </div>
                    </div>
                    <div class="item border-end">
                        <div class="p-4">
                            <a href="javascript:;">
                                <img src="/assets_store_emmeline/assets/images/brands/05.png" class="img-fluid" alt="...">
                            </a>
                        </div>
                    </div>
                    <div class="item border-end">
                        <div class="p-4">
                            <a href="javascript:;">
                                <img src="/assets_store_emmeline/assets/images/brands/06.png" class="img-fluid" alt="...">
                            </a>
                        </div>
                    </div>
                    <div class="item border-end">
                        <div class="p-4">
                            <a href="javascript:;">
                                <img src="/assets_store_emmeline/assets/images/brands/07.png" class="img-fluid" alt="...">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
  export default {
    components: {

    },
    data() {
      return {

      };
    },
    mounted() {

    },
    methods: {

    },
    watch: {

    },
  };
</script>
<style scoped>

</style>
