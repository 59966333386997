<template>
  <div class="page-wrapper">
        <div class="page-content">
            <section class="py-0 py-lg-4">
					<div class="container">
                        <div v-if="this.branchInfo" class="row">
                            <div class="col-md-8">
                                <img src="/assets_store_emmeline/assets/images/about.jpg" class="img-fluid" alt="">
                                <h4>INTRODUCTION</h4>
                                <p>{{ getPreference('Introduction') }}</p>
                                <br /><br />
                                <h4>ABOUT THE PRODUCT</h4>
                                <p>{{ getPreference('About the Product') }}</p>
                            </div>
                            <div class="col-md-4">
                                <img src="/assets_store_emmeline/assets/images/about/about-1.png" class="img-fluid" alt="">
                            </div>
                        </div>
					</div>
				</section>
				<section class="py-4">
					<div class="container">
						<h4>Why Choose Us</h4>
						<hr>
						<div class="row row-cols-1 row-cols-lg-3">
							<div class="col d-flex">
								<div class="card rounded-0 shadow-none w-100">
									<div class="card-body">
										<img src="/assets_store_emmeline/assets/images/icons/delivery.png" width="60" alt="">
										<h5 class="my-3">FREE SHIPPING</h5>
										<p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr in some form.</p>
									</div>
								</div>
							</div>
							<div class="col d-flex">
								<div class="card rounded-0 shadow-none w-100">
									<div class="card-body">
										<img src="/assets_store_emmeline/assets/images/icons/money-bag.png" width="60" alt="">
										<h5 class="my-3">100% MONEY BACK GUARANTEE</h5>
										<p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr in some form.</p>
									</div>
								</div>
							</div>
							<div class="col d-flex">
								<div class="card rounded-0 shadow-none w-100">
									<div class="card-body">
										<img src="/assets_store_emmeline/assets/images/icons/support.png" width="60" alt="">
										<h5 class="my-3">ONLINE SUPPORT 24/7</h5>
										<p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr in some form.</p>
									</div>
								</div>
							</div>
						</div>
						<!--end row-->
					</div>
				</section>

        </div>
    </div>
</template>
<script>

export default {
    components: {

    },
    data() {
        return {
            branchInfo: null,
        };
    },
    mounted() {
        this.branchInfo = this.$parent.branchInfo;
    },
    methods: {
        getPreference(key) {
            let contact = this.branchInfo.contact;
            let value = '';
            contact.forEach(element => {
                if (element.key == key) {
                    value = element.value;
                }
            });
            return value;
        },
    },
};
</script>

<style scoped>
</style>
